import * as Types from '@/types/__generated__/supergraph-ops';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const SignedUploadFragment = gql`
    fragment signedUploadFragment on SignedUploadUrl {
  url
  fields
  key
}
    `;
export const RaMetricHistograms = gql`
    fragment RAMetricHistograms on RAMetricResult {
  histograms {
    binEdges
    target
    reference
  }
}
    `;
export const RaErrorInvalidTargetAsinsFields = gql`
    fragment RAErrorInvalidTargetAsinsFields on RAErrorInvalidTargetAsins {
  asins
}
    `;
export const RaErrorNotEnoughReferenceDataFields = gql`
    fragment RAErrorNotEnoughReferenceDataFields on RAErrorNotEnoughReferenceData {
  required
  available
}
    `;
export const RaErrorNotEnoughTargetDataFields = gql`
    fragment RAErrorNotEnoughTargetDataFields on RAErrorNotEnoughTargetData {
  required
  available
}
    `;
export const RaSummaryFields = gql`
    fragment RASummaryFields on RASummary {
  status
  threshold
  actualVal
}
    `;
export const CancelUploadNewDataFilesDocument = gql`
    mutation CancelUploadNewDataFiles($input: CancelUploadNewDataFilesInput!) {
  cancelUploadNewDataFiles(input: $input) {
    ... on CancelUploadNewDataFilesResponse {
      message
    }
    ... on CancelUploadNewDataFilesError {
      message
      status
    }
  }
}
    `;
export type CancelUploadNewDataFilesMutationFn = Apollo.MutationFunction<Types.CancelUploadNewDataFiles, Types.CancelUploadNewDataFilesVariables>;

/**
 * __useCancelUploadNewDataFiles__
 *
 * To run a mutation, you first call `useCancelUploadNewDataFiles` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelUploadNewDataFiles` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelUploadNewDataFiles, { data, loading, error }] = useCancelUploadNewDataFiles({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelUploadNewDataFiles(baseOptions?: Apollo.MutationHookOptions<Types.CancelUploadNewDataFiles, Types.CancelUploadNewDataFilesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CancelUploadNewDataFiles, Types.CancelUploadNewDataFilesVariables>(CancelUploadNewDataFilesDocument, options);
      }
export type CancelUploadNewDataFilesHookResult = ReturnType<typeof useCancelUploadNewDataFiles>;
export type CancelUploadNewDataFilesMutationResult = Apollo.MutationResult<Types.CancelUploadNewDataFiles>;
export type CancelUploadNewDataFilesMutationOptions = Apollo.BaseMutationOptions<Types.CancelUploadNewDataFiles, Types.CancelUploadNewDataFilesVariables>;
export const GetAffiliateErrorsDocument = gql`
    query GetAffiliateErrors($input: AffiliateValidationErrorRequestInput!) {
  affiliateValidationErrors(input: $input) {
    ... on AffiliateValidationErrorResponse {
      results {
        column
        errorType
        errorDescription
        rows
      }
    }
  }
}
    `;

/**
 * __useGetAffiliateErrors__
 *
 * To run a query within a React component, call `useGetAffiliateErrors` and pass it any options that fit your needs.
 * When your component renders, `useGetAffiliateErrors` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAffiliateErrors({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAffiliateErrors(baseOptions: Apollo.QueryHookOptions<Types.GetAffiliateErrors, Types.GetAffiliateErrorsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAffiliateErrors, Types.GetAffiliateErrorsVariables>(GetAffiliateErrorsDocument, options);
      }
export function useGetAffiliateErrorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAffiliateErrors, Types.GetAffiliateErrorsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAffiliateErrors, Types.GetAffiliateErrorsVariables>(GetAffiliateErrorsDocument, options);
        }
export type GetAffiliateErrorsHookResult = ReturnType<typeof useGetAffiliateErrors>;
export type GetAffiliateErrorsLazyQueryHookResult = ReturnType<typeof useGetAffiliateErrorsLazyQuery>;
export type GetAffiliateErrorsQueryResult = Apollo.QueryResult<Types.GetAffiliateErrors, Types.GetAffiliateErrorsVariables>;
export function refetchGetAffiliateErrors(variables: Types.GetAffiliateErrorsVariables) {
      return { query: GetAffiliateErrorsDocument, variables: variables }
    }
export const GetAffiliateValidationDocument = gql`
    query GetAffiliateValidation($input: AffiliateValidationRequestInput!) {
  affiliateValidation(input: $input) {
    ... on GetValidationsResponse {
      results {
        id
        invoiceDate
        market
        partnerId
        partnerName
        s3DestinationUri
        s3IngestionUri
        status
        source
        useDefault
      }
    }
  }
}
    `;

/**
 * __useGetAffiliateValidation__
 *
 * To run a query within a React component, call `useGetAffiliateValidation` and pass it any options that fit your needs.
 * When your component renders, `useGetAffiliateValidation` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAffiliateValidation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAffiliateValidation(baseOptions: Apollo.QueryHookOptions<Types.GetAffiliateValidation, Types.GetAffiliateValidationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAffiliateValidation, Types.GetAffiliateValidationVariables>(GetAffiliateValidationDocument, options);
      }
export function useGetAffiliateValidationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAffiliateValidation, Types.GetAffiliateValidationVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAffiliateValidation, Types.GetAffiliateValidationVariables>(GetAffiliateValidationDocument, options);
        }
export type GetAffiliateValidationHookResult = ReturnType<typeof useGetAffiliateValidation>;
export type GetAffiliateValidationLazyQueryHookResult = ReturnType<typeof useGetAffiliateValidationLazyQuery>;
export type GetAffiliateValidationQueryResult = Apollo.QueryResult<Types.GetAffiliateValidation, Types.GetAffiliateValidationVariables>;
export function refetchGetAffiliateValidation(variables: Types.GetAffiliateValidationVariables) {
      return { query: GetAffiliateValidationDocument, variables: variables }
    }
export const RequestAffiliateDataDownloadUrlDocument = gql`
    query RequestAffiliateDataDownloadUrl($input: AffiliateDownloadUrlRequest!) {
  requestAffiliateDataDownloadUrl(input: $input) {
    ... on AffiliateS3PresignedDownloadUrl {
      urls
      error
    }
  }
}
    `;

/**
 * __useRequestAffiliateDataDownloadUrl__
 *
 * To run a query within a React component, call `useRequestAffiliateDataDownloadUrl` and pass it any options that fit your needs.
 * When your component renders, `useRequestAffiliateDataDownloadUrl` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestAffiliateDataDownloadUrl({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestAffiliateDataDownloadUrl(baseOptions: Apollo.QueryHookOptions<Types.RequestAffiliateDataDownloadUrl, Types.RequestAffiliateDataDownloadUrlVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RequestAffiliateDataDownloadUrl, Types.RequestAffiliateDataDownloadUrlVariables>(RequestAffiliateDataDownloadUrlDocument, options);
      }
export function useRequestAffiliateDataDownloadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RequestAffiliateDataDownloadUrl, Types.RequestAffiliateDataDownloadUrlVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RequestAffiliateDataDownloadUrl, Types.RequestAffiliateDataDownloadUrlVariables>(RequestAffiliateDataDownloadUrlDocument, options);
        }
export type RequestAffiliateDataDownloadUrlHookResult = ReturnType<typeof useRequestAffiliateDataDownloadUrl>;
export type RequestAffiliateDataDownloadUrlLazyQueryHookResult = ReturnType<typeof useRequestAffiliateDataDownloadUrlLazyQuery>;
export type RequestAffiliateDataDownloadUrlQueryResult = Apollo.QueryResult<Types.RequestAffiliateDataDownloadUrl, Types.RequestAffiliateDataDownloadUrlVariables>;
export function refetchRequestAffiliateDataDownloadUrl(variables: Types.RequestAffiliateDataDownloadUrlVariables) {
      return { query: RequestAffiliateDataDownloadUrlDocument, variables: variables }
    }
export const RequestPartnersDocument = gql`
    query RequestPartners($input: AffiliatePartnerRequestInput!) {
  requestPartners(input: $input) {
    ... on AffiliatePartnerRequestResponse {
      partners {
        partnerId
        partnerName
        validationId
        validationStatus
      }
    }
  }
}
    `;

/**
 * __useRequestPartners__
 *
 * To run a query within a React component, call `useRequestPartners` and pass it any options that fit your needs.
 * When your component renders, `useRequestPartners` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestPartners({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPartners(baseOptions: Apollo.QueryHookOptions<Types.RequestPartners, Types.RequestPartnersVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RequestPartners, Types.RequestPartnersVariables>(RequestPartnersDocument, options);
      }
export function useRequestPartnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RequestPartners, Types.RequestPartnersVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RequestPartners, Types.RequestPartnersVariables>(RequestPartnersDocument, options);
        }
export type RequestPartnersHookResult = ReturnType<typeof useRequestPartners>;
export type RequestPartnersLazyQueryHookResult = ReturnType<typeof useRequestPartnersLazyQuery>;
export type RequestPartnersQueryResult = Apollo.QueryResult<Types.RequestPartners, Types.RequestPartnersVariables>;
export function refetchRequestPartners(variables: Types.RequestPartnersVariables) {
      return { query: RequestPartnersDocument, variables: variables }
    }
export const RequestValidationDocument = gql`
    mutation RequestValidation($input: AffiliateValidationErrorRequestInput!) {
  requestValidation(input: $input) {
    ... on AffiliateRequestValidationResponse {
      message
    }
    ... on AffiliateRequestValidationErrors {
      status
      message
    }
  }
}
    `;
export type RequestValidationMutationFn = Apollo.MutationFunction<Types.RequestValidation, Types.RequestValidationVariables>;

/**
 * __useRequestValidation__
 *
 * To run a mutation, you first call `useRequestValidation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestValidation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestValidation, { data, loading, error }] = useRequestValidation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestValidation(baseOptions?: Apollo.MutationHookOptions<Types.RequestValidation, Types.RequestValidationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestValidation, Types.RequestValidationVariables>(RequestValidationDocument, options);
      }
export type RequestValidationHookResult = ReturnType<typeof useRequestValidation>;
export type RequestValidationMutationResult = Apollo.MutationResult<Types.RequestValidation>;
export type RequestValidationMutationOptions = Apollo.BaseMutationOptions<Types.RequestValidation, Types.RequestValidationVariables>;
export const RequestAffiliateDataUploadUrlDocument = gql`
    mutation RequestAffiliateDataUploadUrl($input: AffiliateS3PresignedPostUrlInput!) {
  requestAffiliateDataUploadUrl(input: $input) {
    ... on AffiliateS3PresignedPostUrl {
      url
      fields
      key
    }
  }
}
    `;
export type RequestAffiliateDataUploadUrlMutationFn = Apollo.MutationFunction<Types.RequestAffiliateDataUploadUrl, Types.RequestAffiliateDataUploadUrlVariables>;

/**
 * __useRequestAffiliateDataUploadUrl__
 *
 * To run a mutation, you first call `useRequestAffiliateDataUploadUrl` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAffiliateDataUploadUrl` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAffiliateDataUploadUrl, { data, loading, error }] = useRequestAffiliateDataUploadUrl({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestAffiliateDataUploadUrl(baseOptions?: Apollo.MutationHookOptions<Types.RequestAffiliateDataUploadUrl, Types.RequestAffiliateDataUploadUrlVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestAffiliateDataUploadUrl, Types.RequestAffiliateDataUploadUrlVariables>(RequestAffiliateDataUploadUrlDocument, options);
      }
export type RequestAffiliateDataUploadUrlHookResult = ReturnType<typeof useRequestAffiliateDataUploadUrl>;
export type RequestAffiliateDataUploadUrlMutationResult = Apollo.MutationResult<Types.RequestAffiliateDataUploadUrl>;
export type RequestAffiliateDataUploadUrlMutationOptions = Apollo.BaseMutationOptions<Types.RequestAffiliateDataUploadUrl, Types.RequestAffiliateDataUploadUrlVariables>;
export const UpdateAffiliateValidationDocument = gql`
    mutation UpdateAffiliateValidation($input: AffiliateValidationUpdateInput!) {
  updateAffiliateValidation(input: $input) {
    ... on AffiliateValidationUpdateResponse {
      message
    }
    ... on AffiliateValidationUpdateResponseError {
      status
      message
    }
  }
}
    `;
export type UpdateAffiliateValidationMutationFn = Apollo.MutationFunction<Types.UpdateAffiliateValidation, Types.UpdateAffiliateValidationVariables>;

/**
 * __useUpdateAffiliateValidation__
 *
 * To run a mutation, you first call `useUpdateAffiliateValidation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAffiliateValidation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAffiliateValidation, { data, loading, error }] = useUpdateAffiliateValidation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAffiliateValidation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAffiliateValidation, Types.UpdateAffiliateValidationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAffiliateValidation, Types.UpdateAffiliateValidationVariables>(UpdateAffiliateValidationDocument, options);
      }
export type UpdateAffiliateValidationHookResult = ReturnType<typeof useUpdateAffiliateValidation>;
export type UpdateAffiliateValidationMutationResult = Apollo.MutationResult<Types.UpdateAffiliateValidation>;
export type UpdateAffiliateValidationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAffiliateValidation, Types.UpdateAffiliateValidationVariables>;
export const UploadNewDataFilesDocument = gql`
    mutation UploadNewDataFiles($input: UploadNewDataFilesInput!) {
  uploadNewDataFiles(input: $input) {
    ... on UploadNewDataFilesResponse {
      message
    }
    ... on UploadNewDataFilesError {
      message
      status
    }
  }
}
    `;
export type UploadNewDataFilesMutationFn = Apollo.MutationFunction<Types.UploadNewDataFiles, Types.UploadNewDataFilesVariables>;

/**
 * __useUploadNewDataFiles__
 *
 * To run a mutation, you first call `useUploadNewDataFiles` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadNewDataFiles` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadNewDataFiles, { data, loading, error }] = useUploadNewDataFiles({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadNewDataFiles(baseOptions?: Apollo.MutationHookOptions<Types.UploadNewDataFiles, Types.UploadNewDataFilesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UploadNewDataFiles, Types.UploadNewDataFilesVariables>(UploadNewDataFilesDocument, options);
      }
export type UploadNewDataFilesHookResult = ReturnType<typeof useUploadNewDataFiles>;
export type UploadNewDataFilesMutationResult = Apollo.MutationResult<Types.UploadNewDataFiles>;
export type UploadNewDataFilesMutationOptions = Apollo.BaseMutationOptions<Types.UploadNewDataFiles, Types.UploadNewDataFilesVariables>;
export const BrandAliasListQueryDocument = gql`
    query BrandAliasListQuery($filter: RequestFilter, $pagination: Pagination, $sort: [SortDescriptor!]) {
  brandAlias(filter: $filter, pagination: $pagination, sort: $sort) {
    results {
      name
    }
    totalResults
  }
}
    `;

/**
 * __useBrandAliasListQuery__
 *
 * To run a query within a React component, call `useBrandAliasListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandAliasListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandAliasListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useBrandAliasListQuery(baseOptions?: Apollo.QueryHookOptions<Types.BrandAliasListQuery, Types.BrandAliasListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BrandAliasListQuery, Types.BrandAliasListQueryVariables>(BrandAliasListQueryDocument, options);
      }
export function useBrandAliasListQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BrandAliasListQuery, Types.BrandAliasListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BrandAliasListQuery, Types.BrandAliasListQueryVariables>(BrandAliasListQueryDocument, options);
        }
export type BrandAliasListQueryHookResult = ReturnType<typeof useBrandAliasListQuery>;
export type BrandAliasListQueryLazyQueryHookResult = ReturnType<typeof useBrandAliasListQueryLazyQuery>;
export type BrandAliasListQueryQueryResult = Apollo.QueryResult<Types.BrandAliasListQuery, Types.BrandAliasListQueryVariables>;
export function refetchBrandAliasListQuery(variables?: Types.BrandAliasListQueryVariables) {
      return { query: BrandAliasListQueryDocument, variables: variables }
    }
export const BrandStatusOptionsQueryDocument = gql`
    query BrandStatusOptionsQuery {
  brandStatus {
    totalResults
    results {
      name
    }
  }
}
    `;

/**
 * __useBrandStatusOptionsQuery__
 *
 * To run a query within a React component, call `useBrandStatusOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandStatusOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandStatusOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandStatusOptionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.BrandStatusOptionsQuery, Types.BrandStatusOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BrandStatusOptionsQuery, Types.BrandStatusOptionsQueryVariables>(BrandStatusOptionsQueryDocument, options);
      }
export function useBrandStatusOptionsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BrandStatusOptionsQuery, Types.BrandStatusOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BrandStatusOptionsQuery, Types.BrandStatusOptionsQueryVariables>(BrandStatusOptionsQueryDocument, options);
        }
export type BrandStatusOptionsQueryHookResult = ReturnType<typeof useBrandStatusOptionsQuery>;
export type BrandStatusOptionsQueryLazyQueryHookResult = ReturnType<typeof useBrandStatusOptionsQueryLazyQuery>;
export type BrandStatusOptionsQueryQueryResult = Apollo.QueryResult<Types.BrandStatusOptionsQuery, Types.BrandStatusOptionsQueryVariables>;
export function refetchBrandStatusOptionsQuery(variables?: Types.BrandStatusOptionsQueryVariables) {
      return { query: BrandStatusOptionsQueryDocument, variables: variables }
    }
export const CreateBrandDocument = gql`
    mutation CreateBrand($input: BrandCreate!) {
  createBrand(input: $input) {
    results {
      createdAt
      acquisitionDate
      divestedDate
      legacyBrand {
        name
      }
      name
      revenueStartDate
      status {
        name
      }
      updatedAt
      wholeBusiness
      wholeDivestBusiness
    }
    error
    success
  }
}
    `;
export type CreateBrandMutationFn = Apollo.MutationFunction<Types.CreateBrand, Types.CreateBrandVariables>;

/**
 * __useCreateBrand__
 *
 * To run a mutation, you first call `useCreateBrand` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrand` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrand, { data, loading, error }] = useCreateBrand({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBrand(baseOptions?: Apollo.MutationHookOptions<Types.CreateBrand, Types.CreateBrandVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateBrand, Types.CreateBrandVariables>(CreateBrandDocument, options);
      }
export type CreateBrandHookResult = ReturnType<typeof useCreateBrand>;
export type CreateBrandMutationResult = Apollo.MutationResult<Types.CreateBrand>;
export type CreateBrandMutationOptions = Apollo.BaseMutationOptions<Types.CreateBrand, Types.CreateBrandVariables>;
export const ProductCatalogBrandListQueryDocument = gql`
    query ProductCatalogBrandListQuery($filter: RequestFilter, $pagination: Pagination, $sort: [SortDescriptor!]) {
  brand(filter: $filter, pagination: $pagination, sort: $sort) {
    results {
      name
      brandAliases {
        name
      }
      status {
        name
      }
      updatedAt
      acquisitionDate
      divestedDate
      wholeBusiness
      wholeDivestBusiness
    }
    totalResults
  }
}
    `;

/**
 * __useProductCatalogBrandListQuery__
 *
 * To run a query within a React component, call `useProductCatalogBrandListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCatalogBrandListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCatalogBrandListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useProductCatalogBrandListQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProductCatalogBrandListQuery, Types.ProductCatalogBrandListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductCatalogBrandListQuery, Types.ProductCatalogBrandListQueryVariables>(ProductCatalogBrandListQueryDocument, options);
      }
export function useProductCatalogBrandListQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductCatalogBrandListQuery, Types.ProductCatalogBrandListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductCatalogBrandListQuery, Types.ProductCatalogBrandListQueryVariables>(ProductCatalogBrandListQueryDocument, options);
        }
export type ProductCatalogBrandListQueryHookResult = ReturnType<typeof useProductCatalogBrandListQuery>;
export type ProductCatalogBrandListQueryLazyQueryHookResult = ReturnType<typeof useProductCatalogBrandListQueryLazyQuery>;
export type ProductCatalogBrandListQueryQueryResult = Apollo.QueryResult<Types.ProductCatalogBrandListQuery, Types.ProductCatalogBrandListQueryVariables>;
export function refetchProductCatalogBrandListQuery(variables?: Types.ProductCatalogBrandListQueryVariables) {
      return { query: ProductCatalogBrandListQueryDocument, variables: variables }
    }
export const ProductCatalogBrandQueryDocument = gql`
    query ProductCatalogBrandQuery($name: String) {
  brand(
    filter: {filters: {operator: EQ, value: $name, field: "name"}, logic: AND}
  ) {
    results {
      createdAt
      acquisitionDate
      divestedDate
      legacyBrand {
        name
      }
      name
      revenueStartDate
      status {
        name
      }
      updatedAt
      wholeBusiness
      wholeDivestBusiness
      brandAliases {
        name
      }
    }
    totalResults
  }
}
    `;

/**
 * __useProductCatalogBrandQuery__
 *
 * To run a query within a React component, call `useProductCatalogBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCatalogBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCatalogBrandQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useProductCatalogBrandQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProductCatalogBrandQuery, Types.ProductCatalogBrandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductCatalogBrandQuery, Types.ProductCatalogBrandQueryVariables>(ProductCatalogBrandQueryDocument, options);
      }
export function useProductCatalogBrandQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductCatalogBrandQuery, Types.ProductCatalogBrandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductCatalogBrandQuery, Types.ProductCatalogBrandQueryVariables>(ProductCatalogBrandQueryDocument, options);
        }
export type ProductCatalogBrandQueryHookResult = ReturnType<typeof useProductCatalogBrandQuery>;
export type ProductCatalogBrandQueryLazyQueryHookResult = ReturnType<typeof useProductCatalogBrandQueryLazyQuery>;
export type ProductCatalogBrandQueryQueryResult = Apollo.QueryResult<Types.ProductCatalogBrandQuery, Types.ProductCatalogBrandQueryVariables>;
export function refetchProductCatalogBrandQuery(variables?: Types.ProductCatalogBrandQueryVariables) {
      return { query: ProductCatalogBrandQueryDocument, variables: variables }
    }
export const UpdateBrandDocument = gql`
    mutation UpdateBrand($input: BrandUpdate!) {
  updateBrand(input: $input) {
    results {
      createdAt
      acquisitionDate
      divestedDate
      legacyBrand {
        name
      }
      name
      revenueStartDate
      status {
        name
      }
      updatedAt
      wholeBusiness
      wholeDivestBusiness
      brandAliases {
        name
      }
    }
    error
    success
  }
}
    `;
export type UpdateBrandMutationFn = Apollo.MutationFunction<Types.UpdateBrand, Types.UpdateBrandVariables>;

/**
 * __useUpdateBrand__
 *
 * To run a mutation, you first call `useUpdateBrand` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrand` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrand, { data, loading, error }] = useUpdateBrand({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBrand(baseOptions?: Apollo.MutationHookOptions<Types.UpdateBrand, Types.UpdateBrandVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateBrand, Types.UpdateBrandVariables>(UpdateBrandDocument, options);
      }
export type UpdateBrandHookResult = ReturnType<typeof useUpdateBrand>;
export type UpdateBrandMutationResult = Apollo.MutationResult<Types.UpdateBrand>;
export type UpdateBrandMutationOptions = Apollo.BaseMutationOptions<Types.UpdateBrand, Types.UpdateBrandVariables>;
export const UpdateBrandAliasDocument = gql`
    mutation UpdateBrandAlias($input: [BrandAliasMutation!]!) {
  updateBrandAlias(input: $input) {
    results {
      name
    }
    error
    success
  }
}
    `;
export type UpdateBrandAliasMutationFn = Apollo.MutationFunction<Types.UpdateBrandAlias, Types.UpdateBrandAliasVariables>;

/**
 * __useUpdateBrandAlias__
 *
 * To run a mutation, you first call `useUpdateBrandAlias` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandAlias` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandAlias, { data, loading, error }] = useUpdateBrandAlias({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBrandAlias(baseOptions?: Apollo.MutationHookOptions<Types.UpdateBrandAlias, Types.UpdateBrandAliasVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateBrandAlias, Types.UpdateBrandAliasVariables>(UpdateBrandAliasDocument, options);
      }
export type UpdateBrandAliasHookResult = ReturnType<typeof useUpdateBrandAlias>;
export type UpdateBrandAliasMutationResult = Apollo.MutationResult<Types.UpdateBrandAlias>;
export type UpdateBrandAliasMutationOptions = Apollo.BaseMutationOptions<Types.UpdateBrandAlias, Types.UpdateBrandAliasVariables>;
export const UpdateInBulkDocument = gql`
    mutation updateInBulk($type: String!, $fileKey: String!, $userEmail: String!) {
  updateInBulk(type: $type, fileKey: $fileKey, userEmail: $userEmail) {
    results
    error
    success
  }
}
    `;
export type UpdateInBulkMutationFn = Apollo.MutationFunction<Types.UpdateInBulk, Types.UpdateInBulkVariables>;

/**
 * __useUpdateInBulk__
 *
 * To run a mutation, you first call `useUpdateInBulk` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInBulk` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInBulk, { data, loading, error }] = useUpdateInBulk({
 *   variables: {
 *      type: // value for 'type'
 *      fileKey: // value for 'fileKey'
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useUpdateInBulk(baseOptions?: Apollo.MutationHookOptions<Types.UpdateInBulk, Types.UpdateInBulkVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateInBulk, Types.UpdateInBulkVariables>(UpdateInBulkDocument, options);
      }
export type UpdateInBulkHookResult = ReturnType<typeof useUpdateInBulk>;
export type UpdateInBulkMutationResult = Apollo.MutationResult<Types.UpdateInBulk>;
export type UpdateInBulkMutationOptions = Apollo.BaseMutationOptions<Types.UpdateInBulk, Types.UpdateInBulkVariables>;
export const BrandsListQueryDocument = gql`
    query BrandsListQuery($filter: RequestFilter, $pagination: Pagination, $sort: [SortDescriptor!]) {
  brand(filter: $filter, pagination: $pagination, sort: $sort) {
    results {
      name
      brandAliases {
        name
      }
    }
    totalResults
  }
}
    `;

/**
 * __useBrandsListQuery__
 *
 * To run a query within a React component, call `useBrandsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useBrandsListQuery(baseOptions?: Apollo.QueryHookOptions<Types.BrandsListQuery, Types.BrandsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BrandsListQuery, Types.BrandsListQueryVariables>(BrandsListQueryDocument, options);
      }
export function useBrandsListQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BrandsListQuery, Types.BrandsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BrandsListQuery, Types.BrandsListQueryVariables>(BrandsListQueryDocument, options);
        }
export type BrandsListQueryHookResult = ReturnType<typeof useBrandsListQuery>;
export type BrandsListQueryLazyQueryHookResult = ReturnType<typeof useBrandsListQueryLazyQuery>;
export type BrandsListQueryQueryResult = Apollo.QueryResult<Types.BrandsListQuery, Types.BrandsListQueryVariables>;
export function refetchBrandsListQuery(variables?: Types.BrandsListQueryVariables) {
      return { query: BrandsListQueryDocument, variables: variables }
    }
export const GetListingsForBrandMappingQueryDocument = gql`
    query getListingsForBrandMappingQuery($filter: RequestFilter, $pagination: Pagination, $sort: [SortDescriptor!]) {
  listingsForBrandMapping(filter: $filter, pagination: $pagination, sort: $sort) {
    results {
      id
      brandAliasName
      brandName
      brandStatus
      channelKey
      channelMarket
      channelSkuId
      channelSkuValue
      createdAt
      market
      name
      storeName
      updatedAt
      planningStatusName
      divestmentStatusName
      divestedDate
    }
    totalResults
  }
}
    `;

/**
 * __useGetListingsForBrandMappingQuery__
 *
 * To run a query within a React component, call `useGetListingsForBrandMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListingsForBrandMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListingsForBrandMappingQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetListingsForBrandMappingQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetListingsForBrandMappingQuery, Types.GetListingsForBrandMappingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetListingsForBrandMappingQuery, Types.GetListingsForBrandMappingQueryVariables>(GetListingsForBrandMappingQueryDocument, options);
      }
export function useGetListingsForBrandMappingQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetListingsForBrandMappingQuery, Types.GetListingsForBrandMappingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetListingsForBrandMappingQuery, Types.GetListingsForBrandMappingQueryVariables>(GetListingsForBrandMappingQueryDocument, options);
        }
export type GetListingsForBrandMappingQueryHookResult = ReturnType<typeof useGetListingsForBrandMappingQuery>;
export type GetListingsForBrandMappingQueryLazyQueryHookResult = ReturnType<typeof useGetListingsForBrandMappingQueryLazyQuery>;
export type GetListingsForBrandMappingQueryQueryResult = Apollo.QueryResult<Types.GetListingsForBrandMappingQuery, Types.GetListingsForBrandMappingQueryVariables>;
export function refetchGetListingsForBrandMappingQuery(variables?: Types.GetListingsForBrandMappingQueryVariables) {
      return { query: GetListingsForBrandMappingQueryDocument, variables: variables }
    }
export const UpdateChannelSkuDocument = gql`
    mutation UpdateChannelSku($input: [ChannelSkuUpdate!]!) {
  updateChannelSku(input: $input) {
    results {
      id
      brandAlias {
        name
      }
    }
    error
    success
  }
}
    `;
export type UpdateChannelSkuMutationFn = Apollo.MutationFunction<Types.UpdateChannelSku, Types.UpdateChannelSkuVariables>;

/**
 * __useUpdateChannelSku__
 *
 * To run a mutation, you first call `useUpdateChannelSku` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelSku` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelSku, { data, loading, error }] = useUpdateChannelSku({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChannelSku(baseOptions?: Apollo.MutationHookOptions<Types.UpdateChannelSku, Types.UpdateChannelSkuVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateChannelSku, Types.UpdateChannelSkuVariables>(UpdateChannelSkuDocument, options);
      }
export type UpdateChannelSkuHookResult = ReturnType<typeof useUpdateChannelSku>;
export type UpdateChannelSkuMutationResult = Apollo.MutationResult<Types.UpdateChannelSku>;
export type UpdateChannelSkuMutationOptions = Apollo.BaseMutationOptions<Types.UpdateChannelSku, Types.UpdateChannelSkuVariables>;
export const UpdateListingToDivestedDocument = gql`
    mutation UpdateListingToDivested($input: ListingToDivest!, $userEmail: String) {
  divestListing(input: $input, userEmail: $userEmail) {
    error
    success
  }
}
    `;
export type UpdateListingToDivestedMutationFn = Apollo.MutationFunction<Types.UpdateListingToDivested, Types.UpdateListingToDivestedVariables>;

/**
 * __useUpdateListingToDivested__
 *
 * To run a mutation, you first call `useUpdateListingToDivested` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListingToDivested` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListingToDivested, { data, loading, error }] = useUpdateListingToDivested({
 *   variables: {
 *      input: // value for 'input'
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useUpdateListingToDivested(baseOptions?: Apollo.MutationHookOptions<Types.UpdateListingToDivested, Types.UpdateListingToDivestedVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateListingToDivested, Types.UpdateListingToDivestedVariables>(UpdateListingToDivestedDocument, options);
      }
export type UpdateListingToDivestedHookResult = ReturnType<typeof useUpdateListingToDivested>;
export type UpdateListingToDivestedMutationResult = Apollo.MutationResult<Types.UpdateListingToDivested>;
export type UpdateListingToDivestedMutationOptions = Apollo.BaseMutationOptions<Types.UpdateListingToDivested, Types.UpdateListingToDivestedVariables>;
export const BidManagementAccountFilterOptionsDocument = gql`
    query BidManagementAccountFilterOptions {
  bidManagementAccountFilterData {
    accounts {
      profileId
      accountName
    }
    errors {
      status
      message
    }
  }
}
    `;

/**
 * __useBidManagementAccountFilterOptions__
 *
 * To run a query within a React component, call `useBidManagementAccountFilterOptions` and pass it any options that fit your needs.
 * When your component renders, `useBidManagementAccountFilterOptions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBidManagementAccountFilterOptions({
 *   variables: {
 *   },
 * });
 */
export function useBidManagementAccountFilterOptions(baseOptions?: Apollo.QueryHookOptions<Types.BidManagementAccountFilterOptions, Types.BidManagementAccountFilterOptionsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BidManagementAccountFilterOptions, Types.BidManagementAccountFilterOptionsVariables>(BidManagementAccountFilterOptionsDocument, options);
      }
export function useBidManagementAccountFilterOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BidManagementAccountFilterOptions, Types.BidManagementAccountFilterOptionsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BidManagementAccountFilterOptions, Types.BidManagementAccountFilterOptionsVariables>(BidManagementAccountFilterOptionsDocument, options);
        }
export type BidManagementAccountFilterOptionsHookResult = ReturnType<typeof useBidManagementAccountFilterOptions>;
export type BidManagementAccountFilterOptionsLazyQueryHookResult = ReturnType<typeof useBidManagementAccountFilterOptionsLazyQuery>;
export type BidManagementAccountFilterOptionsQueryResult = Apollo.QueryResult<Types.BidManagementAccountFilterOptions, Types.BidManagementAccountFilterOptionsVariables>;
export function refetchBidManagementAccountFilterOptions(variables?: Types.BidManagementAccountFilterOptionsVariables) {
      return { query: BidManagementAccountFilterOptionsDocument, variables: variables }
    }
export const BidDataRequestDocument = gql`
    mutation BidDataRequest($asin: [String], $profileId: [String!]!, $bidMin: Float, $bidMax: Float, $acosMin: Float, $acosMax: Float, $statusFilter: [String], $parentCampaignStatus: [String], $campaignFilterType: String, $campaignFilterText: String, $clicksMin: Int, $impressionsMin: Int, $spendMin: Float, $keywordFilter: String, $targetType: String) {
  requestBidManagementData(
    input: {asin: $asin, profileId: $profileId, bidMin: $bidMin, bidMax: $bidMax, acosMin: $acosMin, acosMax: $acosMax, statusFilter: $statusFilter, parentCampaignStatus: $parentCampaignStatus, campaignFilterType: $campaignFilterType, campaignFilterText: $campaignFilterText, clicksMin: $clicksMin, impressionsMin: $impressionsMin, spendMin: $spendMin, keywordFilter: $keywordFilter, targetType: $targetType}
  ) {
    taskId
  }
}
    `;
export type BidDataRequestMutationFn = Apollo.MutationFunction<Types.BidDataRequest, Types.BidDataRequestVariables>;

/**
 * __useBidDataRequest__
 *
 * To run a mutation, you first call `useBidDataRequest` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBidDataRequest` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bidDataRequest, { data, loading, error }] = useBidDataRequest({
 *   variables: {
 *      asin: // value for 'asin'
 *      profileId: // value for 'profileId'
 *      bidMin: // value for 'bidMin'
 *      bidMax: // value for 'bidMax'
 *      acosMin: // value for 'acosMin'
 *      acosMax: // value for 'acosMax'
 *      statusFilter: // value for 'statusFilter'
 *      parentCampaignStatus: // value for 'parentCampaignStatus'
 *      campaignFilterType: // value for 'campaignFilterType'
 *      campaignFilterText: // value for 'campaignFilterText'
 *      clicksMin: // value for 'clicksMin'
 *      impressionsMin: // value for 'impressionsMin'
 *      spendMin: // value for 'spendMin'
 *      keywordFilter: // value for 'keywordFilter'
 *      targetType: // value for 'targetType'
 *   },
 * });
 */
export function useBidDataRequest(baseOptions?: Apollo.MutationHookOptions<Types.BidDataRequest, Types.BidDataRequestVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BidDataRequest, Types.BidDataRequestVariables>(BidDataRequestDocument, options);
      }
export type BidDataRequestHookResult = ReturnType<typeof useBidDataRequest>;
export type BidDataRequestMutationResult = Apollo.MutationResult<Types.BidDataRequest>;
export type BidDataRequestMutationOptions = Apollo.BaseMutationOptions<Types.BidDataRequest, Types.BidDataRequestVariables>;
export const BidDataRequestStatusDocument = gql`
    mutation BidDataRequestStatus($taskId: String!) {
  getBidManagementDataStatus(input: {taskId: $taskId}) {
    taskId
    taskStatus
    taskResult {
      data {
        totalResults
        results {
          asin
          bidId {
            profileId
            account
            targetId
            keywordText
            campaignId
            brandName
            targetType
          }
          details {
            keywordBid {
              currencyCode
              value
            }
            campaignStatus
            campaignName
            parentCampaignStatus
          }
          calculated {
            campaignBudget
            ppcRevenue {
              currencyCode
              value
            }
            acos {
              currencyCode
              value
            }
            spend {
              currencyCode
              value
            }
            sales7d {
              currencyCode
              value
            }
            clicks
            orders7d
            impressions
          }
          pulledTime
        }
      }
      errors {
        status
        message
      }
    }
  }
}
    `;
export type BidDataRequestStatusMutationFn = Apollo.MutationFunction<Types.BidDataRequestStatus, Types.BidDataRequestStatusVariables>;

/**
 * __useBidDataRequestStatus__
 *
 * To run a mutation, you first call `useBidDataRequestStatus` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBidDataRequestStatus` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bidDataRequestStatus, { data, loading, error }] = useBidDataRequestStatus({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useBidDataRequestStatus(baseOptions?: Apollo.MutationHookOptions<Types.BidDataRequestStatus, Types.BidDataRequestStatusVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BidDataRequestStatus, Types.BidDataRequestStatusVariables>(BidDataRequestStatusDocument, options);
      }
export type BidDataRequestStatusHookResult = ReturnType<typeof useBidDataRequestStatus>;
export type BidDataRequestStatusMutationResult = Apollo.MutationResult<Types.BidDataRequestStatus>;
export type BidDataRequestStatusMutationOptions = Apollo.BaseMutationOptions<Types.BidDataRequestStatus, Types.BidDataRequestStatusVariables>;
export const UpdateKeywordDataDocument = gql`
    mutation updateKeywordData($input: BidManagementKeywordUpdateRequestInput!) {
  updateKeywordData(input: $input) {
    isSuccessful
    targetIds
    errors
  }
}
    `;
export type UpdateKeywordDataMutationFn = Apollo.MutationFunction<Types.UpdateKeywordData, Types.UpdateKeywordDataVariables>;

/**
 * __useUpdateKeywordData__
 *
 * To run a mutation, you first call `useUpdateKeywordData` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKeywordData` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKeywordData, { data, loading, error }] = useUpdateKeywordData({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKeywordData(baseOptions?: Apollo.MutationHookOptions<Types.UpdateKeywordData, Types.UpdateKeywordDataVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateKeywordData, Types.UpdateKeywordDataVariables>(UpdateKeywordDataDocument, options);
      }
export type UpdateKeywordDataHookResult = ReturnType<typeof useUpdateKeywordData>;
export type UpdateKeywordDataMutationResult = Apollo.MutationResult<Types.UpdateKeywordData>;
export type UpdateKeywordDataMutationOptions = Apollo.BaseMutationOptions<Types.UpdateKeywordData, Types.UpdateKeywordDataVariables>;
export const BidManagementValStatusDocument = gql`
    mutation BidManagementValStatus($taskId: String!) {
  getBidManagementValStatus(input: {taskId: $taskId}) {
    taskId
    taskStatus
    taskResult {
      isValid
      totalCampaignUpdates
      totalStatusUpdates
      warnings {
        message
        count
      }
      errors {
        message
        count
      }
    }
  }
}
    `;
export type BidManagementValStatusMutationFn = Apollo.MutationFunction<Types.BidManagementValStatus, Types.BidManagementValStatusVariables>;

/**
 * __useBidManagementValStatus__
 *
 * To run a mutation, you first call `useBidManagementValStatus` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBidManagementValStatus` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bidManagementValStatus, { data, loading, error }] = useBidManagementValStatus({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useBidManagementValStatus(baseOptions?: Apollo.MutationHookOptions<Types.BidManagementValStatus, Types.BidManagementValStatusVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BidManagementValStatus, Types.BidManagementValStatusVariables>(BidManagementValStatusDocument, options);
      }
export type BidManagementValStatusHookResult = ReturnType<typeof useBidManagementValStatus>;
export type BidManagementValStatusMutationResult = Apollo.MutationResult<Types.BidManagementValStatus>;
export type BidManagementValStatusMutationOptions = Apollo.BaseMutationOptions<Types.BidManagementValStatus, Types.BidManagementValStatusVariables>;
export const ParseBidDataFromUploadedFileDocument = gql`
    mutation parseBidDataFromUploadedFile($input: BidManagementValidationRequestInput!) {
  parseBidDataFromUploadedFile(input: $input) {
    taskId
  }
}
    `;
export type ParseBidDataFromUploadedFileMutationFn = Apollo.MutationFunction<Types.ParseBidDataFromUploadedFile, Types.ParseBidDataFromUploadedFileVariables>;

/**
 * __useParseBidDataFromUploadedFile__
 *
 * To run a mutation, you first call `useParseBidDataFromUploadedFile` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParseBidDataFromUploadedFile` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parseBidDataFromUploadedFile, { data, loading, error }] = useParseBidDataFromUploadedFile({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useParseBidDataFromUploadedFile(baseOptions?: Apollo.MutationHookOptions<Types.ParseBidDataFromUploadedFile, Types.ParseBidDataFromUploadedFileVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ParseBidDataFromUploadedFile, Types.ParseBidDataFromUploadedFileVariables>(ParseBidDataFromUploadedFileDocument, options);
      }
export type ParseBidDataFromUploadedFileHookResult = ReturnType<typeof useParseBidDataFromUploadedFile>;
export type ParseBidDataFromUploadedFileMutationResult = Apollo.MutationResult<Types.ParseBidDataFromUploadedFile>;
export type ParseBidDataFromUploadedFileMutationOptions = Apollo.BaseMutationOptions<Types.ParseBidDataFromUploadedFile, Types.ParseBidDataFromUploadedFileVariables>;
export const RequestBidDataUploadUrlDocument = gql`
    mutation requestBidDataUploadUrl($input: BidManagementValidationUploadRequestInput!) {
  requestBidDataUploadUrl(input: $input) {
    s3PresignedPostUrl {
      url
      fields
      key
    }
    validationRecordId
  }
}
    `;
export type RequestBidDataUploadUrlMutationFn = Apollo.MutationFunction<Types.RequestBidDataUploadUrl, Types.RequestBidDataUploadUrlVariables>;

/**
 * __useRequestBidDataUploadUrl__
 *
 * To run a mutation, you first call `useRequestBidDataUploadUrl` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestBidDataUploadUrl` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestBidDataUploadUrl, { data, loading, error }] = useRequestBidDataUploadUrl({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestBidDataUploadUrl(baseOptions?: Apollo.MutationHookOptions<Types.RequestBidDataUploadUrl, Types.RequestBidDataUploadUrlVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestBidDataUploadUrl, Types.RequestBidDataUploadUrlVariables>(RequestBidDataUploadUrlDocument, options);
      }
export type RequestBidDataUploadUrlHookResult = ReturnType<typeof useRequestBidDataUploadUrl>;
export type RequestBidDataUploadUrlMutationResult = Apollo.MutationResult<Types.RequestBidDataUploadUrl>;
export type RequestBidDataUploadUrlMutationOptions = Apollo.BaseMutationOptions<Types.RequestBidDataUploadUrl, Types.RequestBidDataUploadUrlVariables>;
export const CreateS3UploadUrlDocument = gql`
    mutation CreateS3UploadUrl($filename: String!, $foldername: String!) {
  createS3UploadUrl(filename: $filename, foldername: $foldername) {
    fields
    key
    url
  }
}
    `;
export type CreateS3UploadUrlMutationFn = Apollo.MutationFunction<Types.CreateS3UploadUrl, Types.CreateS3UploadUrlVariables>;

/**
 * __useCreateS3UploadUrl__
 *
 * To run a mutation, you first call `useCreateS3UploadUrl` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateS3UploadUrl` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createS3UploadUrl, { data, loading, error }] = useCreateS3UploadUrl({
 *   variables: {
 *      filename: // value for 'filename'
 *      foldername: // value for 'foldername'
 *   },
 * });
 */
export function useCreateS3UploadUrl(baseOptions?: Apollo.MutationHookOptions<Types.CreateS3UploadUrl, Types.CreateS3UploadUrlVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateS3UploadUrl, Types.CreateS3UploadUrlVariables>(CreateS3UploadUrlDocument, options);
      }
export type CreateS3UploadUrlHookResult = ReturnType<typeof useCreateS3UploadUrl>;
export type CreateS3UploadUrlMutationResult = Apollo.MutationResult<Types.CreateS3UploadUrl>;
export type CreateS3UploadUrlMutationOptions = Apollo.BaseMutationOptions<Types.CreateS3UploadUrl, Types.CreateS3UploadUrlVariables>;
export const GetListingsForPlanningStatusQueryDocument = gql`
    query getListingsForPlanningStatusQuery($filter: RequestFilter, $pagination: Pagination, $sort: [SortDescriptor!]) {
  listingsForPlanningStatus(filter: $filter, pagination: $pagination, sort: $sort) {
    results {
      podName
      brandName
      channelKey
      channelMarket
      channelName
      channelSkuValue
      createdAt
      fulfillmentTypeKey
      grossRevenueLastYear
      grossRevenueLastYearForListing
      id
      inventoryOnHandForListing
      isPrimaryListing
      market
      groupPlanningStatusName
      planningSuggestionInfoId
      storeName
      storeKey
      suggestedStatusName
      updatedAt
      name
    }
    totalResults
  }
}
    `;

/**
 * __useGetListingsForPlanningStatusQuery__
 *
 * To run a query within a React component, call `useGetListingsForPlanningStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListingsForPlanningStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListingsForPlanningStatusQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetListingsForPlanningStatusQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetListingsForPlanningStatusQuery, Types.GetListingsForPlanningStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetListingsForPlanningStatusQuery, Types.GetListingsForPlanningStatusQueryVariables>(GetListingsForPlanningStatusQueryDocument, options);
      }
export function useGetListingsForPlanningStatusQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetListingsForPlanningStatusQuery, Types.GetListingsForPlanningStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetListingsForPlanningStatusQuery, Types.GetListingsForPlanningStatusQueryVariables>(GetListingsForPlanningStatusQueryDocument, options);
        }
export type GetListingsForPlanningStatusQueryHookResult = ReturnType<typeof useGetListingsForPlanningStatusQuery>;
export type GetListingsForPlanningStatusQueryLazyQueryHookResult = ReturnType<typeof useGetListingsForPlanningStatusQueryLazyQuery>;
export type GetListingsForPlanningStatusQueryQueryResult = Apollo.QueryResult<Types.GetListingsForPlanningStatusQuery, Types.GetListingsForPlanningStatusQueryVariables>;
export function refetchGetListingsForPlanningStatusQuery(variables?: Types.GetListingsForPlanningStatusQueryVariables) {
      return { query: GetListingsForPlanningStatusQueryDocument, variables: variables }
    }
export const GetListingsForPlanningStatusReportQueryDocument = gql`
    query getListingsForPlanningStatusReportQuery($filter: RequestFilter, $pagination: Pagination, $sort: [SortDescriptor!], $userEmail: String) {
  listingsForPlanningStatusReport(
    filter: $filter
    pagination: $pagination
    sort: $sort
    userEmail: $userEmail
  ) {
    totalResults
  }
}
    `;

/**
 * __useGetListingsForPlanningStatusReportQuery__
 *
 * To run a query within a React component, call `useGetListingsForPlanningStatusReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListingsForPlanningStatusReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListingsForPlanningStatusReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useGetListingsForPlanningStatusReportQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetListingsForPlanningStatusReportQuery, Types.GetListingsForPlanningStatusReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetListingsForPlanningStatusReportQuery, Types.GetListingsForPlanningStatusReportQueryVariables>(GetListingsForPlanningStatusReportQueryDocument, options);
      }
export function useGetListingsForPlanningStatusReportQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetListingsForPlanningStatusReportQuery, Types.GetListingsForPlanningStatusReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetListingsForPlanningStatusReportQuery, Types.GetListingsForPlanningStatusReportQueryVariables>(GetListingsForPlanningStatusReportQueryDocument, options);
        }
export type GetListingsForPlanningStatusReportQueryHookResult = ReturnType<typeof useGetListingsForPlanningStatusReportQuery>;
export type GetListingsForPlanningStatusReportQueryLazyQueryHookResult = ReturnType<typeof useGetListingsForPlanningStatusReportQueryLazyQuery>;
export type GetListingsForPlanningStatusReportQueryQueryResult = Apollo.QueryResult<Types.GetListingsForPlanningStatusReportQuery, Types.GetListingsForPlanningStatusReportQueryVariables>;
export function refetchGetListingsForPlanningStatusReportQuery(variables?: Types.GetListingsForPlanningStatusReportQueryVariables) {
      return { query: GetListingsForPlanningStatusReportQueryDocument, variables: variables }
    }
export const GetListingsForReviewPlanningStatusQueryDocument = gql`
    query getListingsForReviewPlanningStatusQuery($filter: RequestFilter, $pagination: Pagination, $sort: [SortDescriptor!]) {
  listingsForReviewPlanningStatus(
    filter: $filter
    pagination: $pagination
    sort: $sort
  ) {
    results {
      brandName
      channelKey
      channelMarket
      channelName
      channelSkuValue
      createdAt
      fulfillmentTypeKey
      grossRevenueLastYear
      grossRevenueLastYearForListing
      id
      inventoryOnHand
      inventoryOnHandForListing
      isPrimaryListing
      market
      groupPlanningStatusName
      planningSuggestionInfoId
      storeName
      storeKey
      suggestedStatusName
      updatedAt
      name
    }
    totalResults
  }
}
    `;

/**
 * __useGetListingsForReviewPlanningStatusQuery__
 *
 * To run a query within a React component, call `useGetListingsForReviewPlanningStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListingsForReviewPlanningStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListingsForReviewPlanningStatusQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetListingsForReviewPlanningStatusQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetListingsForReviewPlanningStatusQuery, Types.GetListingsForReviewPlanningStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetListingsForReviewPlanningStatusQuery, Types.GetListingsForReviewPlanningStatusQueryVariables>(GetListingsForReviewPlanningStatusQueryDocument, options);
      }
export function useGetListingsForReviewPlanningStatusQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetListingsForReviewPlanningStatusQuery, Types.GetListingsForReviewPlanningStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetListingsForReviewPlanningStatusQuery, Types.GetListingsForReviewPlanningStatusQueryVariables>(GetListingsForReviewPlanningStatusQueryDocument, options);
        }
export type GetListingsForReviewPlanningStatusQueryHookResult = ReturnType<typeof useGetListingsForReviewPlanningStatusQuery>;
export type GetListingsForReviewPlanningStatusQueryLazyQueryHookResult = ReturnType<typeof useGetListingsForReviewPlanningStatusQueryLazyQuery>;
export type GetListingsForReviewPlanningStatusQueryQueryResult = Apollo.QueryResult<Types.GetListingsForReviewPlanningStatusQuery, Types.GetListingsForReviewPlanningStatusQueryVariables>;
export function refetchGetListingsForReviewPlanningStatusQuery(variables?: Types.GetListingsForReviewPlanningStatusQueryVariables) {
      return { query: GetListingsForReviewPlanningStatusQueryDocument, variables: variables }
    }
export const GetPrimaryListingsSelectionDocument = gql`
    query getPrimaryListingsSelection($filter: RequestFilter, $pagination: Pagination, $sort: [SortDescriptor!]) {
  listingsForPlanningStatus(filter: $filter, pagination: $pagination, sort: $sort) {
    results {
      brandName
      channelKey
      channelMarket
      channelName
      channelSkuValue
      createdAt
      fulfillmentTypeKey
      grossRevenueLastYear
      grossRevenueLastYearForListing
      id
      inventoryOnHand
      inventoryOnHandForListing
      isPrimaryListing
      market
      groupPlanningStatusName
      planningSuggestionInfoId
      storeName
      storeKey
      suggestedStatusName
      updatedAt
    }
    totalResults
  }
}
    `;

/**
 * __useGetPrimaryListingsSelection__
 *
 * To run a query within a React component, call `useGetPrimaryListingsSelection` and pass it any options that fit your needs.
 * When your component renders, `useGetPrimaryListingsSelection` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrimaryListingsSelection({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetPrimaryListingsSelection(baseOptions?: Apollo.QueryHookOptions<Types.GetPrimaryListingsSelection, Types.GetPrimaryListingsSelectionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPrimaryListingsSelection, Types.GetPrimaryListingsSelectionVariables>(GetPrimaryListingsSelectionDocument, options);
      }
export function useGetPrimaryListingsSelectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPrimaryListingsSelection, Types.GetPrimaryListingsSelectionVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPrimaryListingsSelection, Types.GetPrimaryListingsSelectionVariables>(GetPrimaryListingsSelectionDocument, options);
        }
export type GetPrimaryListingsSelectionHookResult = ReturnType<typeof useGetPrimaryListingsSelection>;
export type GetPrimaryListingsSelectionLazyQueryHookResult = ReturnType<typeof useGetPrimaryListingsSelectionLazyQuery>;
export type GetPrimaryListingsSelectionQueryResult = Apollo.QueryResult<Types.GetPrimaryListingsSelection, Types.GetPrimaryListingsSelectionVariables>;
export function refetchGetPrimaryListingsSelection(variables?: Types.GetPrimaryListingsSelectionVariables) {
      return { query: GetPrimaryListingsSelectionDocument, variables: variables }
    }
export const UpdateGroupPlanningStatusDocument = gql`
    mutation UpdateGroupPlanningStatus($input: GroupPlanningStatusUpdate!, $userEmail: String!) {
  updateGroupPlanningStatus(input: $input, userEmail: $userEmail) {
    results
    error
    success
  }
}
    `;
export type UpdateGroupPlanningStatusMutationFn = Apollo.MutationFunction<Types.UpdateGroupPlanningStatus, Types.UpdateGroupPlanningStatusVariables>;

/**
 * __useUpdateGroupPlanningStatus__
 *
 * To run a mutation, you first call `useUpdateGroupPlanningStatus` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupPlanningStatus` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupPlanningStatus, { data, loading, error }] = useUpdateGroupPlanningStatus({
 *   variables: {
 *      input: // value for 'input'
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useUpdateGroupPlanningStatus(baseOptions?: Apollo.MutationHookOptions<Types.UpdateGroupPlanningStatus, Types.UpdateGroupPlanningStatusVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateGroupPlanningStatus, Types.UpdateGroupPlanningStatusVariables>(UpdateGroupPlanningStatusDocument, options);
      }
export type UpdateGroupPlanningStatusHookResult = ReturnType<typeof useUpdateGroupPlanningStatus>;
export type UpdateGroupPlanningStatusMutationResult = Apollo.MutationResult<Types.UpdateGroupPlanningStatus>;
export type UpdateGroupPlanningStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateGroupPlanningStatus, Types.UpdateGroupPlanningStatusVariables>;
export const MarkListingAsPrimaryDocument = gql`
    mutation MarkListingAsPrimary($input: MarkListingAsPrimaryInput!, $userEmail: String!) {
  markListingAsPrimary(input: $input, userEmail: $userEmail) {
    results
    error
    success
  }
}
    `;
export type MarkListingAsPrimaryMutationFn = Apollo.MutationFunction<Types.MarkListingAsPrimary, Types.MarkListingAsPrimaryVariables>;

/**
 * __useMarkListingAsPrimary__
 *
 * To run a mutation, you first call `useMarkListingAsPrimary` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkListingAsPrimary` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markListingAsPrimary, { data, loading, error }] = useMarkListingAsPrimary({
 *   variables: {
 *      input: // value for 'input'
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useMarkListingAsPrimary(baseOptions?: Apollo.MutationHookOptions<Types.MarkListingAsPrimary, Types.MarkListingAsPrimaryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MarkListingAsPrimary, Types.MarkListingAsPrimaryVariables>(MarkListingAsPrimaryDocument, options);
      }
export type MarkListingAsPrimaryHookResult = ReturnType<typeof useMarkListingAsPrimary>;
export type MarkListingAsPrimaryMutationResult = Apollo.MutationResult<Types.MarkListingAsPrimary>;
export type MarkListingAsPrimaryMutationOptions = Apollo.BaseMutationOptions<Types.MarkListingAsPrimary, Types.MarkListingAsPrimaryVariables>;
export const AcquiredBrandListDocument = gql`
    query AcquiredBrandList {
  brand {
    results {
      name
    }
  }
}
    `;

/**
 * __useAcquiredBrandList__
 *
 * To run a query within a React component, call `useAcquiredBrandList` and pass it any options that fit your needs.
 * When your component renders, `useAcquiredBrandList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcquiredBrandList({
 *   variables: {
 *   },
 * });
 */
export function useAcquiredBrandList(baseOptions?: Apollo.QueryHookOptions<Types.AcquiredBrandList, Types.AcquiredBrandListVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AcquiredBrandList, Types.AcquiredBrandListVariables>(AcquiredBrandListDocument, options);
      }
export function useAcquiredBrandListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AcquiredBrandList, Types.AcquiredBrandListVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AcquiredBrandList, Types.AcquiredBrandListVariables>(AcquiredBrandListDocument, options);
        }
export type AcquiredBrandListHookResult = ReturnType<typeof useAcquiredBrandList>;
export type AcquiredBrandListLazyQueryHookResult = ReturnType<typeof useAcquiredBrandListLazyQuery>;
export type AcquiredBrandListQueryResult = Apollo.QueryResult<Types.AcquiredBrandList, Types.AcquiredBrandListVariables>;
export function refetchAcquiredBrandList(variables?: Types.AcquiredBrandListVariables) {
      return { query: AcquiredBrandListDocument, variables: variables }
    }
export const DimUnitsQueryDocument = gql`
    query DimUnitsQuery {
  dimUnits {
    results {
      name
    }
  }
}
    `;

/**
 * __useDimUnitsQuery__
 *
 * To run a query within a React component, call `useDimUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDimUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDimUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDimUnitsQuery(baseOptions?: Apollo.QueryHookOptions<Types.DimUnitsQuery, Types.DimUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DimUnitsQuery, Types.DimUnitsQueryVariables>(DimUnitsQueryDocument, options);
      }
export function useDimUnitsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DimUnitsQuery, Types.DimUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DimUnitsQuery, Types.DimUnitsQueryVariables>(DimUnitsQueryDocument, options);
        }
export type DimUnitsQueryHookResult = ReturnType<typeof useDimUnitsQuery>;
export type DimUnitsQueryLazyQueryHookResult = ReturnType<typeof useDimUnitsQueryLazyQuery>;
export type DimUnitsQueryQueryResult = Apollo.QueryResult<Types.DimUnitsQuery, Types.DimUnitsQueryVariables>;
export function refetchDimUnitsQuery(variables?: Types.DimUnitsQueryVariables) {
      return { query: DimUnitsQueryDocument, variables: variables }
    }
export const CreateRawSkuDocument = gql`
    mutation CreateRawSku($input: RawSkuCreate!) {
  createRawSku(input: $input) {
    results {
      acquiredBrand
      skuType
      id
      rawSku
      sourceType
      source
      barcodeType
      barcode
      thrasioSku
    }
    success
    error
  }
}
    `;
export type CreateRawSkuMutationFn = Apollo.MutationFunction<Types.CreateRawSku, Types.CreateRawSkuVariables>;

/**
 * __useCreateRawSku__
 *
 * To run a mutation, you first call `useCreateRawSku` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRawSku` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRawSku, { data, loading, error }] = useCreateRawSku({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRawSku(baseOptions?: Apollo.MutationHookOptions<Types.CreateRawSku, Types.CreateRawSkuVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateRawSku, Types.CreateRawSkuVariables>(CreateRawSkuDocument, options);
      }
export type CreateRawSkuHookResult = ReturnType<typeof useCreateRawSku>;
export type CreateRawSkuMutationResult = Apollo.MutationResult<Types.CreateRawSku>;
export type CreateRawSkuMutationOptions = Apollo.BaseMutationOptions<Types.CreateRawSku, Types.CreateRawSkuVariables>;
export const RawSkuSingleQueryDocument = gql`
    query RawSkuSingleQuery($filter: RequestFilter) {
  rawSku(filter: $filter) {
    results {
      completeness
      status
      baseUnitTypePerSku
      barCodes {
        source
        sourceType
        type
        value
      }
      description
      id
      moq
      purchasePrice
      purchaseLeadTime
      skuDimensions {
        dimension
        unit
        value
      }
      brand {
        name
      }
      thrasioSku {
        dateIntroduced
        value
        type
        id
      }
      value
      vendor
      vendorSubsidiary
    }
  }
}
    `;

/**
 * __useRawSkuSingleQuery__
 *
 * To run a query within a React component, call `useRawSkuSingleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawSkuSingleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawSkuSingleQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRawSkuSingleQuery(baseOptions?: Apollo.QueryHookOptions<Types.RawSkuSingleQuery, Types.RawSkuSingleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RawSkuSingleQuery, Types.RawSkuSingleQueryVariables>(RawSkuSingleQueryDocument, options);
      }
export function useRawSkuSingleQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RawSkuSingleQuery, Types.RawSkuSingleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RawSkuSingleQuery, Types.RawSkuSingleQueryVariables>(RawSkuSingleQueryDocument, options);
        }
export type RawSkuSingleQueryHookResult = ReturnType<typeof useRawSkuSingleQuery>;
export type RawSkuSingleQueryLazyQueryHookResult = ReturnType<typeof useRawSkuSingleQueryLazyQuery>;
export type RawSkuSingleQueryQueryResult = Apollo.QueryResult<Types.RawSkuSingleQuery, Types.RawSkuSingleQueryVariables>;
export function refetchRawSkuSingleQuery(variables?: Types.RawSkuSingleQueryVariables) {
      return { query: RawSkuSingleQueryDocument, variables: variables }
    }
export const SourceTypeQueryDocument = gql`
    query SourceTypeQuery {
  sourceType {
    results {
      name
    }
  }
}
    `;

/**
 * __useSourceTypeQuery__
 *
 * To run a query within a React component, call `useSourceTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourceTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourceTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useSourceTypeQuery(baseOptions?: Apollo.QueryHookOptions<Types.SourceTypeQuery, Types.SourceTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SourceTypeQuery, Types.SourceTypeQueryVariables>(SourceTypeQueryDocument, options);
      }
export function useSourceTypeQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SourceTypeQuery, Types.SourceTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SourceTypeQuery, Types.SourceTypeQueryVariables>(SourceTypeQueryDocument, options);
        }
export type SourceTypeQueryHookResult = ReturnType<typeof useSourceTypeQuery>;
export type SourceTypeQueryLazyQueryHookResult = ReturnType<typeof useSourceTypeQueryLazyQuery>;
export type SourceTypeQueryQueryResult = Apollo.QueryResult<Types.SourceTypeQuery, Types.SourceTypeQueryVariables>;
export function refetchSourceTypeQuery(variables?: Types.SourceTypeQueryVariables) {
      return { query: SourceTypeQueryDocument, variables: variables }
    }
export const TypeListQueryDocument = gql`
    query TypeListQuery {
  barcodeType {
    results {
      name
    }
  }
  skuType {
    results {
      name
    }
  }
  sourceType {
    results {
      name
    }
  }
}
    `;

/**
 * __useTypeListQuery__
 *
 * To run a query within a React component, call `useTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useTypeListQuery(baseOptions?: Apollo.QueryHookOptions<Types.TypeListQuery, Types.TypeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TypeListQuery, Types.TypeListQueryVariables>(TypeListQueryDocument, options);
      }
export function useTypeListQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TypeListQuery, Types.TypeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TypeListQuery, Types.TypeListQueryVariables>(TypeListQueryDocument, options);
        }
export type TypeListQueryHookResult = ReturnType<typeof useTypeListQuery>;
export type TypeListQueryLazyQueryHookResult = ReturnType<typeof useTypeListQueryLazyQuery>;
export type TypeListQueryQueryResult = Apollo.QueryResult<Types.TypeListQuery, Types.TypeListQueryVariables>;
export function refetchTypeListQuery(variables?: Types.TypeListQueryVariables) {
      return { query: TypeListQueryDocument, variables: variables }
    }
export const UpdateBulkRawSkuDocument = gql`
    mutation UpdateBulkRawSku($fileKey: String!, $creator: String!) {
  updateBulkSkuMapping(fileKey: $fileKey, creator: $creator) {
    results
    error
    success
  }
}
    `;
export type UpdateBulkRawSkuMutationFn = Apollo.MutationFunction<Types.UpdateBulkRawSku, Types.UpdateBulkRawSkuVariables>;

/**
 * __useUpdateBulkRawSku__
 *
 * To run a mutation, you first call `useUpdateBulkRawSku` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulkRawSku` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulkRawSku, { data, loading, error }] = useUpdateBulkRawSku({
 *   variables: {
 *      fileKey: // value for 'fileKey'
 *      creator: // value for 'creator'
 *   },
 * });
 */
export function useUpdateBulkRawSku(baseOptions?: Apollo.MutationHookOptions<Types.UpdateBulkRawSku, Types.UpdateBulkRawSkuVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateBulkRawSku, Types.UpdateBulkRawSkuVariables>(UpdateBulkRawSkuDocument, options);
      }
export type UpdateBulkRawSkuHookResult = ReturnType<typeof useUpdateBulkRawSku>;
export type UpdateBulkRawSkuMutationResult = Apollo.MutationResult<Types.UpdateBulkRawSku>;
export type UpdateBulkRawSkuMutationOptions = Apollo.BaseMutationOptions<Types.UpdateBulkRawSku, Types.UpdateBulkRawSkuVariables>;
export const UpdateCasePackDimDocument = gql`
    mutation UpdateCasePackDim($input: RawSkuTripleDimensionUpdate!) {
  updateRawSkuCasePackDimension(input: $input) {
    results {
      height
      id
      length
      unit
      width
    }
  }
}
    `;
export type UpdateCasePackDimMutationFn = Apollo.MutationFunction<Types.UpdateCasePackDim, Types.UpdateCasePackDimVariables>;

/**
 * __useUpdateCasePackDim__
 *
 * To run a mutation, you first call `useUpdateCasePackDim` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCasePackDim` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCasePackDim, { data, loading, error }] = useUpdateCasePackDim({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCasePackDim(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCasePackDim, Types.UpdateCasePackDimVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCasePackDim, Types.UpdateCasePackDimVariables>(UpdateCasePackDimDocument, options);
      }
export type UpdateCasePackDimHookResult = ReturnType<typeof useUpdateCasePackDim>;
export type UpdateCasePackDimMutationResult = Apollo.MutationResult<Types.UpdateCasePackDim>;
export type UpdateCasePackDimMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCasePackDim, Types.UpdateCasePackDimVariables>;
export const UpdateCasePackQtyDocument = gql`
    mutation UpdateCasePackQty($input: RawSkuGeneralDimUpdate!) {
  updateRawSkuCasePackQty(input: $input) {
    success
    error
    results {
      id
      unit
      value
    }
  }
}
    `;
export type UpdateCasePackQtyMutationFn = Apollo.MutationFunction<Types.UpdateCasePackQty, Types.UpdateCasePackQtyVariables>;

/**
 * __useUpdateCasePackQty__
 *
 * To run a mutation, you first call `useUpdateCasePackQty` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCasePackQty` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCasePackQty, { data, loading, error }] = useUpdateCasePackQty({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCasePackQty(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCasePackQty, Types.UpdateCasePackQtyVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCasePackQty, Types.UpdateCasePackQtyVariables>(UpdateCasePackQtyDocument, options);
      }
export type UpdateCasePackQtyHookResult = ReturnType<typeof useUpdateCasePackQty>;
export type UpdateCasePackQtyMutationResult = Apollo.MutationResult<Types.UpdateCasePackQty>;
export type UpdateCasePackQtyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCasePackQty, Types.UpdateCasePackQtyVariables>;
export const UpdateCasePackVolumeDocument = gql`
    mutation UpdateCasePackVolume($input: RawSkuGeneralDimUpdate!) {
  updateRawSkuCasePackVolume(input: $input) {
    success
    error
    results {
      id
      unit
      value
    }
  }
}
    `;
export type UpdateCasePackVolumeMutationFn = Apollo.MutationFunction<Types.UpdateCasePackVolume, Types.UpdateCasePackVolumeVariables>;

/**
 * __useUpdateCasePackVolume__
 *
 * To run a mutation, you first call `useUpdateCasePackVolume` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCasePackVolume` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCasePackVolume, { data, loading, error }] = useUpdateCasePackVolume({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCasePackVolume(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCasePackVolume, Types.UpdateCasePackVolumeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCasePackVolume, Types.UpdateCasePackVolumeVariables>(UpdateCasePackVolumeDocument, options);
      }
export type UpdateCasePackVolumeHookResult = ReturnType<typeof useUpdateCasePackVolume>;
export type UpdateCasePackVolumeMutationResult = Apollo.MutationResult<Types.UpdateCasePackVolume>;
export type UpdateCasePackVolumeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCasePackVolume, Types.UpdateCasePackVolumeVariables>;
export const UpdateCasePackWeightDocument = gql`
    mutation UpdateCasePackWeight($input: RawSkuGeneralDimUpdate!) {
  updateRawSkuCasePackWeight(input: $input) {
    success
    error
    results {
      id
      unit
      value
    }
  }
}
    `;
export type UpdateCasePackWeightMutationFn = Apollo.MutationFunction<Types.UpdateCasePackWeight, Types.UpdateCasePackWeightVariables>;

/**
 * __useUpdateCasePackWeight__
 *
 * To run a mutation, you first call `useUpdateCasePackWeight` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCasePackWeight` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCasePackWeight, { data, loading, error }] = useUpdateCasePackWeight({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCasePackWeight(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCasePackWeight, Types.UpdateCasePackWeightVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCasePackWeight, Types.UpdateCasePackWeightVariables>(UpdateCasePackWeightDocument, options);
      }
export type UpdateCasePackWeightHookResult = ReturnType<typeof useUpdateCasePackWeight>;
export type UpdateCasePackWeightMutationResult = Apollo.MutationResult<Types.UpdateCasePackWeight>;
export type UpdateCasePackWeightMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCasePackWeight, Types.UpdateCasePackWeightVariables>;
export const UpdateContainerQtyDocument = gql`
    mutation UpdateContainerQty($input: RawSkuGeneralDimUpdate!) {
  updateRawSkuContainerQty(input: $input) {
    success
    error
    results {
      id
      unit
      value
    }
  }
}
    `;
export type UpdateContainerQtyMutationFn = Apollo.MutationFunction<Types.UpdateContainerQty, Types.UpdateContainerQtyVariables>;

/**
 * __useUpdateContainerQty__
 *
 * To run a mutation, you first call `useUpdateContainerQty` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContainerQty` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContainerQty, { data, loading, error }] = useUpdateContainerQty({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContainerQty(baseOptions?: Apollo.MutationHookOptions<Types.UpdateContainerQty, Types.UpdateContainerQtyVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateContainerQty, Types.UpdateContainerQtyVariables>(UpdateContainerQtyDocument, options);
      }
export type UpdateContainerQtyHookResult = ReturnType<typeof useUpdateContainerQty>;
export type UpdateContainerQtyMutationResult = Apollo.MutationResult<Types.UpdateContainerQty>;
export type UpdateContainerQtyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateContainerQty, Types.UpdateContainerQtyVariables>;
export const UpdateInnerCasePackQtyDocument = gql`
    mutation UpdateInnerCasePackQty($input: RawSkuGeneralDimUpdate!) {
  updateRawSkuInnerCasePackQty(input: $input) {
    success
    error
    results {
      id
      unit
      value
    }
  }
}
    `;
export type UpdateInnerCasePackQtyMutationFn = Apollo.MutationFunction<Types.UpdateInnerCasePackQty, Types.UpdateInnerCasePackQtyVariables>;

/**
 * __useUpdateInnerCasePackQty__
 *
 * To run a mutation, you first call `useUpdateInnerCasePackQty` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInnerCasePackQty` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInnerCasePackQty, { data, loading, error }] = useUpdateInnerCasePackQty({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInnerCasePackQty(baseOptions?: Apollo.MutationHookOptions<Types.UpdateInnerCasePackQty, Types.UpdateInnerCasePackQtyVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateInnerCasePackQty, Types.UpdateInnerCasePackQtyVariables>(UpdateInnerCasePackQtyDocument, options);
      }
export type UpdateInnerCasePackQtyHookResult = ReturnType<typeof useUpdateInnerCasePackQty>;
export type UpdateInnerCasePackQtyMutationResult = Apollo.MutationResult<Types.UpdateInnerCasePackQty>;
export type UpdateInnerCasePackQtyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateInnerCasePackQty, Types.UpdateInnerCasePackQtyVariables>;
export const UpdateItemVolumeDocument = gql`
    mutation UpdateItemVolume($input: RawSkuGeneralDimUpdate!) {
  updateRawSkuProductVolume(input: $input) {
    success
    error
    results {
      id
      unit
      value
    }
  }
}
    `;
export type UpdateItemVolumeMutationFn = Apollo.MutationFunction<Types.UpdateItemVolume, Types.UpdateItemVolumeVariables>;

/**
 * __useUpdateItemVolume__
 *
 * To run a mutation, you first call `useUpdateItemVolume` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemVolume` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemVolume, { data, loading, error }] = useUpdateItemVolume({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemVolume(baseOptions?: Apollo.MutationHookOptions<Types.UpdateItemVolume, Types.UpdateItemVolumeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateItemVolume, Types.UpdateItemVolumeVariables>(UpdateItemVolumeDocument, options);
      }
export type UpdateItemVolumeHookResult = ReturnType<typeof useUpdateItemVolume>;
export type UpdateItemVolumeMutationResult = Apollo.MutationResult<Types.UpdateItemVolume>;
export type UpdateItemVolumeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateItemVolume, Types.UpdateItemVolumeVariables>;
export const UpdatePalletQtyDocument = gql`
    mutation UpdatePalletQty($input: RawSkuGeneralDimUpdate!) {
  updateRawSkuPalletQty(input: $input) {
    success
    error
    results {
      id
      unit
      value
    }
  }
}
    `;
export type UpdatePalletQtyMutationFn = Apollo.MutationFunction<Types.UpdatePalletQty, Types.UpdatePalletQtyVariables>;

/**
 * __useUpdatePalletQty__
 *
 * To run a mutation, you first call `useUpdatePalletQty` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePalletQty` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePalletQty, { data, loading, error }] = useUpdatePalletQty({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePalletQty(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePalletQty, Types.UpdatePalletQtyVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePalletQty, Types.UpdatePalletQtyVariables>(UpdatePalletQtyDocument, options);
      }
export type UpdatePalletQtyHookResult = ReturnType<typeof useUpdatePalletQty>;
export type UpdatePalletQtyMutationResult = Apollo.MutationResult<Types.UpdatePalletQty>;
export type UpdatePalletQtyMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePalletQty, Types.UpdatePalletQtyVariables>;
export const UpdateProductDimensionDocument = gql`
    mutation UpdateProductDimension($input: RawSkuTripleDimensionUpdate!) {
  updateRawSkuProductDimension(input: $input) {
    results {
      height
      id
      length
      unit
      width
    }
  }
}
    `;
export type UpdateProductDimensionMutationFn = Apollo.MutationFunction<Types.UpdateProductDimension, Types.UpdateProductDimensionVariables>;

/**
 * __useUpdateProductDimension__
 *
 * To run a mutation, you first call `useUpdateProductDimension` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductDimension` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductDimension, { data, loading, error }] = useUpdateProductDimension({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductDimension(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProductDimension, Types.UpdateProductDimensionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProductDimension, Types.UpdateProductDimensionVariables>(UpdateProductDimensionDocument, options);
      }
export type UpdateProductDimensionHookResult = ReturnType<typeof useUpdateProductDimension>;
export type UpdateProductDimensionMutationResult = Apollo.MutationResult<Types.UpdateProductDimension>;
export type UpdateProductDimensionMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProductDimension, Types.UpdateProductDimensionVariables>;
export const UpdateRawSkuDocument = gql`
    mutation UpdateRawSku($input: RawSkuUpdate!) {
  updateRawSku(input: $input) {
    results {
      id
      description
      moq
      purchaseLeadTime
      vendor
      vendorSubsidiary
      purchasePrice
      baseUnitTypePerSku
      creator
    }
    success
    error
  }
}
    `;
export type UpdateRawSkuMutationFn = Apollo.MutationFunction<Types.UpdateRawSku, Types.UpdateRawSkuVariables>;

/**
 * __useUpdateRawSku__
 *
 * To run a mutation, you first call `useUpdateRawSku` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRawSku` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRawSku, { data, loading, error }] = useUpdateRawSku({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRawSku(baseOptions?: Apollo.MutationHookOptions<Types.UpdateRawSku, Types.UpdateRawSkuVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateRawSku, Types.UpdateRawSkuVariables>(UpdateRawSkuDocument, options);
      }
export type UpdateRawSkuHookResult = ReturnType<typeof useUpdateRawSku>;
export type UpdateRawSkuMutationResult = Apollo.MutationResult<Types.UpdateRawSku>;
export type UpdateRawSkuMutationOptions = Apollo.BaseMutationOptions<Types.UpdateRawSku, Types.UpdateRawSkuVariables>;
export const RawSkuBulkUpdateStatusDocument = gql`
    query RawSkuBulkUpdateStatus($filekey: String!) {
  bulkUpdateResponse(fileKey: $filekey)
}
    `;

/**
 * __useRawSkuBulkUpdateStatus__
 *
 * To run a query within a React component, call `useRawSkuBulkUpdateStatus` and pass it any options that fit your needs.
 * When your component renders, `useRawSkuBulkUpdateStatus` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawSkuBulkUpdateStatus({
 *   variables: {
 *      filekey: // value for 'filekey'
 *   },
 * });
 */
export function useRawSkuBulkUpdateStatus(baseOptions: Apollo.QueryHookOptions<Types.RawSkuBulkUpdateStatus, Types.RawSkuBulkUpdateStatusVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RawSkuBulkUpdateStatus, Types.RawSkuBulkUpdateStatusVariables>(RawSkuBulkUpdateStatusDocument, options);
      }
export function useRawSkuBulkUpdateStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RawSkuBulkUpdateStatus, Types.RawSkuBulkUpdateStatusVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RawSkuBulkUpdateStatus, Types.RawSkuBulkUpdateStatusVariables>(RawSkuBulkUpdateStatusDocument, options);
        }
export type RawSkuBulkUpdateStatusHookResult = ReturnType<typeof useRawSkuBulkUpdateStatus>;
export type RawSkuBulkUpdateStatusLazyQueryHookResult = ReturnType<typeof useRawSkuBulkUpdateStatusLazyQuery>;
export type RawSkuBulkUpdateStatusQueryResult = Apollo.QueryResult<Types.RawSkuBulkUpdateStatus, Types.RawSkuBulkUpdateStatusVariables>;
export function refetchRawSkuBulkUpdateStatus(variables: Types.RawSkuBulkUpdateStatusVariables) {
      return { query: RawSkuBulkUpdateStatusDocument, variables: variables }
    }
export const RawSkuStatusListDocument = gql`
    query RawSkuStatusList {
  rawSkuStatus {
    totalResults
    results {
      name
    }
  }
}
    `;

/**
 * __useRawSkuStatusList__
 *
 * To run a query within a React component, call `useRawSkuStatusList` and pass it any options that fit your needs.
 * When your component renders, `useRawSkuStatusList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawSkuStatusList({
 *   variables: {
 *   },
 * });
 */
export function useRawSkuStatusList(baseOptions?: Apollo.QueryHookOptions<Types.RawSkuStatusList, Types.RawSkuStatusListVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RawSkuStatusList, Types.RawSkuStatusListVariables>(RawSkuStatusListDocument, options);
      }
export function useRawSkuStatusListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RawSkuStatusList, Types.RawSkuStatusListVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RawSkuStatusList, Types.RawSkuStatusListVariables>(RawSkuStatusListDocument, options);
        }
export type RawSkuStatusListHookResult = ReturnType<typeof useRawSkuStatusList>;
export type RawSkuStatusListLazyQueryHookResult = ReturnType<typeof useRawSkuStatusListLazyQuery>;
export type RawSkuStatusListQueryResult = Apollo.QueryResult<Types.RawSkuStatusList, Types.RawSkuStatusListVariables>;
export function refetchRawSkuStatusList(variables?: Types.RawSkuStatusListVariables) {
      return { query: RawSkuStatusListDocument, variables: variables }
    }
export const SkuStatusListDocument = gql`
    query SkuStatusList {
  rawSkuStatus {
    totalResults
    results {
      name
    }
  }
}
    `;

/**
 * __useSkuStatusList__
 *
 * To run a query within a React component, call `useSkuStatusList` and pass it any options that fit your needs.
 * When your component renders, `useSkuStatusList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkuStatusList({
 *   variables: {
 *   },
 * });
 */
export function useSkuStatusList(baseOptions?: Apollo.QueryHookOptions<Types.SkuStatusList, Types.SkuStatusListVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SkuStatusList, Types.SkuStatusListVariables>(SkuStatusListDocument, options);
      }
export function useSkuStatusListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SkuStatusList, Types.SkuStatusListVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SkuStatusList, Types.SkuStatusListVariables>(SkuStatusListDocument, options);
        }
export type SkuStatusListHookResult = ReturnType<typeof useSkuStatusList>;
export type SkuStatusListLazyQueryHookResult = ReturnType<typeof useSkuStatusListLazyQuery>;
export type SkuStatusListQueryResult = Apollo.QueryResult<Types.SkuStatusList, Types.SkuStatusListVariables>;
export function refetchSkuStatusList(variables?: Types.SkuStatusListVariables) {
      return { query: SkuStatusListDocument, variables: variables }
    }
export const SkuTypeListDocument = gql`
    query SkuTypeList {
  skuType {
    totalResults
    results {
      name
    }
  }
}
    `;

/**
 * __useSkuTypeList__
 *
 * To run a query within a React component, call `useSkuTypeList` and pass it any options that fit your needs.
 * When your component renders, `useSkuTypeList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkuTypeList({
 *   variables: {
 *   },
 * });
 */
export function useSkuTypeList(baseOptions?: Apollo.QueryHookOptions<Types.SkuTypeList, Types.SkuTypeListVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SkuTypeList, Types.SkuTypeListVariables>(SkuTypeListDocument, options);
      }
export function useSkuTypeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SkuTypeList, Types.SkuTypeListVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SkuTypeList, Types.SkuTypeListVariables>(SkuTypeListDocument, options);
        }
export type SkuTypeListHookResult = ReturnType<typeof useSkuTypeList>;
export type SkuTypeListLazyQueryHookResult = ReturnType<typeof useSkuTypeListLazyQuery>;
export type SkuTypeListQueryResult = Apollo.QueryResult<Types.SkuTypeList, Types.SkuTypeListVariables>;
export function refetchSkuTypeList(variables?: Types.SkuTypeListVariables) {
      return { query: SkuTypeListDocument, variables: variables }
    }
export const ThrasioSkuQueryDocument = gql`
    query ThrasioSkuQuery($ID: String) {
  __typename
  thrasioSku(
    filter: {filters: {field: "ID", operator: EQ, value: $ID}, logic: AND}
  ) {
    totalResults
    results {
      dateIntroduced
      type
      value
      id
    }
  }
}
    `;

/**
 * __useThrasioSkuQuery__
 *
 * To run a query within a React component, call `useThrasioSkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useThrasioSkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThrasioSkuQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useThrasioSkuQuery(baseOptions?: Apollo.QueryHookOptions<Types.ThrasioSkuQuery, Types.ThrasioSkuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ThrasioSkuQuery, Types.ThrasioSkuQueryVariables>(ThrasioSkuQueryDocument, options);
      }
export function useThrasioSkuQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ThrasioSkuQuery, Types.ThrasioSkuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ThrasioSkuQuery, Types.ThrasioSkuQueryVariables>(ThrasioSkuQueryDocument, options);
        }
export type ThrasioSkuQueryHookResult = ReturnType<typeof useThrasioSkuQuery>;
export type ThrasioSkuQueryLazyQueryHookResult = ReturnType<typeof useThrasioSkuQueryLazyQuery>;
export type ThrasioSkuQueryQueryResult = Apollo.QueryResult<Types.ThrasioSkuQuery, Types.ThrasioSkuQueryVariables>;
export function refetchThrasioSkuQuery(variables?: Types.ThrasioSkuQueryVariables) {
      return { query: ThrasioSkuQueryDocument, variables: variables }
    }
export const UpdateThrasioSkuDocument = gql`
    mutation updateThrasioSku($input: ThrasioSkuUpdate!) {
  __typename
  updateThrasioSku(input: $input) {
    results {
      dateIntroduced
      status
      value
      id
    }
  }
}
    `;
export type UpdateThrasioSkuMutationFn = Apollo.MutationFunction<Types.UpdateThrasioSku, Types.UpdateThrasioSkuVariables>;

/**
 * __useUpdateThrasioSku__
 *
 * To run a mutation, you first call `useUpdateThrasioSku` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThrasioSku` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThrasioSku, { data, loading, error }] = useUpdateThrasioSku({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateThrasioSku(baseOptions?: Apollo.MutationHookOptions<Types.UpdateThrasioSku, Types.UpdateThrasioSkuVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateThrasioSku, Types.UpdateThrasioSkuVariables>(UpdateThrasioSkuDocument, options);
      }
export type UpdateThrasioSkuHookResult = ReturnType<typeof useUpdateThrasioSku>;
export type UpdateThrasioSkuMutationResult = Apollo.MutationResult<Types.UpdateThrasioSku>;
export type UpdateThrasioSkuMutationOptions = Apollo.BaseMutationOptions<Types.UpdateThrasioSku, Types.UpdateThrasioSkuVariables>;
export const UpdateWeightDocument = gql`
    mutation UpdateWeight($input: RawSkuGeneralDimUpdate!) {
  updateRawSkuWeight(input: $input) {
    success
    error
    results {
      id
      unit
      value
    }
  }
}
    `;
export type UpdateWeightMutationFn = Apollo.MutationFunction<Types.UpdateWeight, Types.UpdateWeightVariables>;

/**
 * __useUpdateWeight__
 *
 * To run a mutation, you first call `useUpdateWeight` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWeight` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWeight, { data, loading, error }] = useUpdateWeight({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWeight(baseOptions?: Apollo.MutationHookOptions<Types.UpdateWeight, Types.UpdateWeightVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateWeight, Types.UpdateWeightVariables>(UpdateWeightDocument, options);
      }
export type UpdateWeightHookResult = ReturnType<typeof useUpdateWeight>;
export type UpdateWeightMutationResult = Apollo.MutationResult<Types.UpdateWeight>;
export type UpdateWeightMutationOptions = Apollo.BaseMutationOptions<Types.UpdateWeight, Types.UpdateWeightVariables>;
export const StoreListQueryDocument = gql`
    query StoreListQuery($filter: RequestFilter, $pagination: Pagination, $sort: [SortDescriptor!]) {
  store(filter: $filter, pagination: $pagination, sort: $sort) {
    results {
      id
      key
      name
      channel {
        name
      }
      market {
        name
      }
      diligence
      storeStatus {
        name
      }
      divestedDate
      createdAt
      updatedAt
    }
    totalResults
  }
}
    `;

/**
 * __useStoreListQuery__
 *
 * To run a query within a React component, call `useStoreListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useStoreListQuery(baseOptions?: Apollo.QueryHookOptions<Types.StoreListQuery, Types.StoreListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StoreListQuery, Types.StoreListQueryVariables>(StoreListQueryDocument, options);
      }
export function useStoreListQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StoreListQuery, Types.StoreListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StoreListQuery, Types.StoreListQueryVariables>(StoreListQueryDocument, options);
        }
export type StoreListQueryHookResult = ReturnType<typeof useStoreListQuery>;
export type StoreListQueryLazyQueryHookResult = ReturnType<typeof useStoreListQueryLazyQuery>;
export type StoreListQueryQueryResult = Apollo.QueryResult<Types.StoreListQuery, Types.StoreListQueryVariables>;
export function refetchStoreListQuery(variables?: Types.StoreListQueryVariables) {
      return { query: StoreListQueryDocument, variables: variables }
    }
export const StoreQueryDocument = gql`
    query StoreQuery($id: String) {
  store(filter: {filters: {operator: EQ, value: $id, field: "id"}, logic: AND}) {
    results {
      id
      key
      name
      channel {
        name
      }
      market {
        name
      }
      diligence
      storeStatus {
        name
      }
      divestedDate
      createdAt
      updatedAt
    }
    totalResults
  }
}
    `;

/**
 * __useStoreQuery__
 *
 * To run a query within a React component, call `useStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStoreQuery(baseOptions?: Apollo.QueryHookOptions<Types.StoreQuery, Types.StoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StoreQuery, Types.StoreQueryVariables>(StoreQueryDocument, options);
      }
export function useStoreQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StoreQuery, Types.StoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StoreQuery, Types.StoreQueryVariables>(StoreQueryDocument, options);
        }
export type StoreQueryHookResult = ReturnType<typeof useStoreQuery>;
export type StoreQueryLazyQueryHookResult = ReturnType<typeof useStoreQueryLazyQuery>;
export type StoreQueryQueryResult = Apollo.QueryResult<Types.StoreQuery, Types.StoreQueryVariables>;
export function refetchStoreQuery(variables?: Types.StoreQueryVariables) {
      return { query: StoreQueryDocument, variables: variables }
    }
export const UpdateStoreDocument = gql`
    mutation UpdateStore($input: StoreUpdate!) {
  updateStore(input: $input) {
    results
    error
    success
  }
}
    `;
export type UpdateStoreMutationFn = Apollo.MutationFunction<Types.UpdateStore, Types.UpdateStoreVariables>;

/**
 * __useUpdateStore__
 *
 * To run a mutation, you first call `useUpdateStore` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStore` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStore, { data, loading, error }] = useUpdateStore({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStore(baseOptions?: Apollo.MutationHookOptions<Types.UpdateStore, Types.UpdateStoreVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateStore, Types.UpdateStoreVariables>(UpdateStoreDocument, options);
      }
export type UpdateStoreHookResult = ReturnType<typeof useUpdateStore>;
export type UpdateStoreMutationResult = Apollo.MutationResult<Types.UpdateStore>;
export type UpdateStoreMutationOptions = Apollo.BaseMutationOptions<Types.UpdateStore, Types.UpdateStoreVariables>;
export const GetPoCreationDataDocument = gql`
    query GetPoCreationData {
  getPoCreationInstructions {
    result {
      poInstructionId
      dateAdded
      status
      error
      brand
      market
      buyer
      channelId
      sku
      listingStatus
      erd
      quantity
      vendor
      leadTime
      moq
      paymentTerms
      incoterms
      unitCost
      totalCost
      latestDeliveryDate
      prepayment
    }
  }
}
    `;

/**
 * __useGetPoCreationData__
 *
 * To run a query within a React component, call `useGetPoCreationData` and pass it any options that fit your needs.
 * When your component renders, `useGetPoCreationData` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoCreationData({
 *   variables: {
 *   },
 * });
 */
export function useGetPoCreationData(baseOptions?: Apollo.QueryHookOptions<Types.GetPoCreationData, Types.GetPoCreationDataVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPoCreationData, Types.GetPoCreationDataVariables>(GetPoCreationDataDocument, options);
      }
export function useGetPoCreationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPoCreationData, Types.GetPoCreationDataVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPoCreationData, Types.GetPoCreationDataVariables>(GetPoCreationDataDocument, options);
        }
export type GetPoCreationDataHookResult = ReturnType<typeof useGetPoCreationData>;
export type GetPoCreationDataLazyQueryHookResult = ReturnType<typeof useGetPoCreationDataLazyQuery>;
export type GetPoCreationDataQueryResult = Apollo.QueryResult<Types.GetPoCreationData, Types.GetPoCreationDataVariables>;
export function refetchGetPoCreationData(variables?: Types.GetPoCreationDataVariables) {
      return { query: GetPoCreationDataDocument, variables: variables }
    }
export const CheckDocumentWasProcessedDocument = gql`
    query checkDocumentWasProcessed($input: CheckDocumentProcessedInput!) {
  checkDocumentWasProcessed(input: $input) {
    success
    message
    isProcessed
  }
}
    `;

/**
 * __useCheckDocumentWasProcessed__
 *
 * To run a query within a React component, call `useCheckDocumentWasProcessed` and pass it any options that fit your needs.
 * When your component renders, `useCheckDocumentWasProcessed` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDocumentWasProcessed({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckDocumentWasProcessed(baseOptions: Apollo.QueryHookOptions<Types.CheckDocumentWasProcessed, Types.CheckDocumentWasProcessedVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckDocumentWasProcessed, Types.CheckDocumentWasProcessedVariables>(CheckDocumentWasProcessedDocument, options);
      }
export function useCheckDocumentWasProcessedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckDocumentWasProcessed, Types.CheckDocumentWasProcessedVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckDocumentWasProcessed, Types.CheckDocumentWasProcessedVariables>(CheckDocumentWasProcessedDocument, options);
        }
export type CheckDocumentWasProcessedHookResult = ReturnType<typeof useCheckDocumentWasProcessed>;
export type CheckDocumentWasProcessedLazyQueryHookResult = ReturnType<typeof useCheckDocumentWasProcessedLazyQuery>;
export type CheckDocumentWasProcessedQueryResult = Apollo.QueryResult<Types.CheckDocumentWasProcessed, Types.CheckDocumentWasProcessedVariables>;
export function refetchCheckDocumentWasProcessed(variables: Types.CheckDocumentWasProcessedVariables) {
      return { query: CheckDocumentWasProcessedDocument, variables: variables }
    }
export const PoExecutionUploadCsvDocument = gql`
    mutation PoExecutionUploadCsv($input: PoCSVUploadDocumentInput!) {
  poExecutionUploadCsv(input: $input) {
    success
    errors
    documentID
  }
}
    `;
export type PoExecutionUploadCsvMutationFn = Apollo.MutationFunction<Types.PoExecutionUploadCsv, Types.PoExecutionUploadCsvVariables>;

/**
 * __usePoExecutionUploadCsv__
 *
 * To run a mutation, you first call `usePoExecutionUploadCsv` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePoExecutionUploadCsv` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [poExecutionUploadCsv, { data, loading, error }] = usePoExecutionUploadCsv({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePoExecutionUploadCsv(baseOptions?: Apollo.MutationHookOptions<Types.PoExecutionUploadCsv, Types.PoExecutionUploadCsvVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PoExecutionUploadCsv, Types.PoExecutionUploadCsvVariables>(PoExecutionUploadCsvDocument, options);
      }
export type PoExecutionUploadCsvHookResult = ReturnType<typeof usePoExecutionUploadCsv>;
export type PoExecutionUploadCsvMutationResult = Apollo.MutationResult<Types.PoExecutionUploadCsv>;
export type PoExecutionUploadCsvMutationOptions = Apollo.BaseMutationOptions<Types.PoExecutionUploadCsv, Types.PoExecutionUploadCsvVariables>;
export const PoExecutionUploadUrlDocument = gql`
    mutation PoExecutionUploadUrl {
  poExecutionUploadUrl {
    ...signedUploadFragment
  }
}
    ${SignedUploadFragment}`;
export type PoExecutionUploadUrlMutationFn = Apollo.MutationFunction<Types.PoExecutionUploadUrl, Types.PoExecutionUploadUrlVariables>;

/**
 * __usePoExecutionUploadUrl__
 *
 * To run a mutation, you first call `usePoExecutionUploadUrl` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePoExecutionUploadUrl` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [poExecutionUploadUrl, { data, loading, error }] = usePoExecutionUploadUrl({
 *   variables: {
 *   },
 * });
 */
export function usePoExecutionUploadUrl(baseOptions?: Apollo.MutationHookOptions<Types.PoExecutionUploadUrl, Types.PoExecutionUploadUrlVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PoExecutionUploadUrl, Types.PoExecutionUploadUrlVariables>(PoExecutionUploadUrlDocument, options);
      }
export type PoExecutionUploadUrlHookResult = ReturnType<typeof usePoExecutionUploadUrl>;
export type PoExecutionUploadUrlMutationResult = Apollo.MutationResult<Types.PoExecutionUploadUrl>;
export type PoExecutionUploadUrlMutationOptions = Apollo.BaseMutationOptions<Types.PoExecutionUploadUrl, Types.PoExecutionUploadUrlVariables>;
export const GetPoDataDocument = gql`
    query GetPoData {
  getPoData {
    result {
      channelId
      erd
      poLineId
      poNumber
      quantity
      sellerAccount
      destinationType
      status
      daysUntilErd
      createdDate
      casepackSize
      memo
      shipmentOwner
      sku
      omniCoordinator
      market
      fromCountry
      issue
      amzError
      gmlCoordinator
      shipUnit
      shipUnitVolumeCubicMeter
    }
  }
}
    `;

/**
 * __useGetPoData__
 *
 * To run a query within a React component, call `useGetPoData` and pass it any options that fit your needs.
 * When your component renders, `useGetPoData` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoData({
 *   variables: {
 *   },
 * });
 */
export function useGetPoData(baseOptions?: Apollo.QueryHookOptions<Types.GetPoData, Types.GetPoDataVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPoData, Types.GetPoDataVariables>(GetPoDataDocument, options);
      }
export function useGetPoDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPoData, Types.GetPoDataVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPoData, Types.GetPoDataVariables>(GetPoDataDocument, options);
        }
export type GetPoDataHookResult = ReturnType<typeof useGetPoData>;
export type GetPoDataLazyQueryHookResult = ReturnType<typeof useGetPoDataLazyQuery>;
export type GetPoDataQueryResult = Apollo.QueryResult<Types.GetPoData, Types.GetPoDataVariables>;
export function refetchGetPoData(variables?: Types.GetPoDataVariables) {
      return { query: GetPoDataDocument, variables: variables }
    }
export const ProductIntegrityGetRequestLinksDocument = gql`
    query productIntegrityGetRequestLinks($requestId: String!) {
  productIntegrityGetRequestLinks(requestId: $requestId) {
    results {
      step
      url
    }
    success
    errors
  }
}
    `;

/**
 * __useProductIntegrityGetRequestLinks__
 *
 * To run a query within a React component, call `useProductIntegrityGetRequestLinks` and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityGetRequestLinks` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductIntegrityGetRequestLinks({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useProductIntegrityGetRequestLinks(baseOptions: Apollo.QueryHookOptions<Types.ProductIntegrityGetRequestLinks, Types.ProductIntegrityGetRequestLinksVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductIntegrityGetRequestLinks, Types.ProductIntegrityGetRequestLinksVariables>(ProductIntegrityGetRequestLinksDocument, options);
      }
export function useProductIntegrityGetRequestLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductIntegrityGetRequestLinks, Types.ProductIntegrityGetRequestLinksVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductIntegrityGetRequestLinks, Types.ProductIntegrityGetRequestLinksVariables>(ProductIntegrityGetRequestLinksDocument, options);
        }
export type ProductIntegrityGetRequestLinksHookResult = ReturnType<typeof useProductIntegrityGetRequestLinks>;
export type ProductIntegrityGetRequestLinksLazyQueryHookResult = ReturnType<typeof useProductIntegrityGetRequestLinksLazyQuery>;
export type ProductIntegrityGetRequestLinksQueryResult = Apollo.QueryResult<Types.ProductIntegrityGetRequestLinks, Types.ProductIntegrityGetRequestLinksVariables>;
export function refetchProductIntegrityGetRequestLinks(variables: Types.ProductIntegrityGetRequestLinksVariables) {
      return { query: ProductIntegrityGetRequestLinksDocument, variables: variables }
    }
export const ProductIntegritySubmitCertificatesDocument = gql`
    mutation productIntegritySubmitCertificates($input: ProductIntegrityStepFourSubmitInput!) {
  productIntegritySubmitCertificates(input: $input) {
    errors
    success
  }
}
    `;
export type ProductIntegritySubmitCertificatesMutationFn = Apollo.MutationFunction<Types.ProductIntegritySubmitCertificates, Types.ProductIntegritySubmitCertificatesVariables>;

/**
 * __useProductIntegritySubmitCertificates__
 *
 * To run a mutation, you first call `useProductIntegritySubmitCertificates` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductIntegritySubmitCertificates` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productIntegritySubmitCertificates, { data, loading, error }] = useProductIntegritySubmitCertificates({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductIntegritySubmitCertificates(baseOptions?: Apollo.MutationHookOptions<Types.ProductIntegritySubmitCertificates, Types.ProductIntegritySubmitCertificatesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductIntegritySubmitCertificates, Types.ProductIntegritySubmitCertificatesVariables>(ProductIntegritySubmitCertificatesDocument, options);
      }
export type ProductIntegritySubmitCertificatesHookResult = ReturnType<typeof useProductIntegritySubmitCertificates>;
export type ProductIntegritySubmitCertificatesMutationResult = Apollo.MutationResult<Types.ProductIntegritySubmitCertificates>;
export type ProductIntegritySubmitCertificatesMutationOptions = Apollo.BaseMutationOptions<Types.ProductIntegritySubmitCertificates, Types.ProductIntegritySubmitCertificatesVariables>;
export const ProductIntegrityCreateLabDocument = gql`
    mutation productIntegrityCreateLab($lab: ProductIntegrityLabInput!) {
  productIntegrityCreateLab(lab: $lab) {
    errors
    success
    labInfo {
      labId
      labName
      labContact
      labAddress
      labEmail
      labPhoneNumber
      labMobileNumber
      labFax
    }
  }
}
    `;
export type ProductIntegrityCreateLabMutationFn = Apollo.MutationFunction<Types.ProductIntegrityCreateLab, Types.ProductIntegrityCreateLabVariables>;

/**
 * __useProductIntegrityCreateLab__
 *
 * To run a mutation, you first call `useProductIntegrityCreateLab` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityCreateLab` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productIntegrityCreateLab, { data, loading, error }] = useProductIntegrityCreateLab({
 *   variables: {
 *      lab: // value for 'lab'
 *   },
 * });
 */
export function useProductIntegrityCreateLab(baseOptions?: Apollo.MutationHookOptions<Types.ProductIntegrityCreateLab, Types.ProductIntegrityCreateLabVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductIntegrityCreateLab, Types.ProductIntegrityCreateLabVariables>(ProductIntegrityCreateLabDocument, options);
      }
export type ProductIntegrityCreateLabHookResult = ReturnType<typeof useProductIntegrityCreateLab>;
export type ProductIntegrityCreateLabMutationResult = Apollo.MutationResult<Types.ProductIntegrityCreateLab>;
export type ProductIntegrityCreateLabMutationOptions = Apollo.BaseMutationOptions<Types.ProductIntegrityCreateLab, Types.ProductIntegrityCreateLabVariables>;
export const ProductIntegrityCreateReviewDocument = gql`
    mutation productIntegrityCreateReview($input: ProductIntegrityRequestReviewInput!, $id: String!) {
  productIntegrityCreateReview(reviewInput: $input, requestId: $id) {
    success
    errors
  }
}
    `;
export type ProductIntegrityCreateReviewMutationFn = Apollo.MutationFunction<Types.ProductIntegrityCreateReview, Types.ProductIntegrityCreateReviewVariables>;

/**
 * __useProductIntegrityCreateReview__
 *
 * To run a mutation, you first call `useProductIntegrityCreateReview` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityCreateReview` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productIntegrityCreateReview, { data, loading, error }] = useProductIntegrityCreateReview({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductIntegrityCreateReview(baseOptions?: Apollo.MutationHookOptions<Types.ProductIntegrityCreateReview, Types.ProductIntegrityCreateReviewVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductIntegrityCreateReview, Types.ProductIntegrityCreateReviewVariables>(ProductIntegrityCreateReviewDocument, options);
      }
export type ProductIntegrityCreateReviewHookResult = ReturnType<typeof useProductIntegrityCreateReview>;
export type ProductIntegrityCreateReviewMutationResult = Apollo.MutationResult<Types.ProductIntegrityCreateReview>;
export type ProductIntegrityCreateReviewMutationOptions = Apollo.BaseMutationOptions<Types.ProductIntegrityCreateReview, Types.ProductIntegrityCreateReviewVariables>;
export const ProductIntegrityGetLabInfoDocument = gql`
    query productIntegrityGetLabInfo {
  productIntegrityGetLabInfo {
    results {
      name
      contactName
      phoneNumber
      mobileNumber
      faxNumber
      email
      location
    }
    success
    errors
  }
}
    `;

/**
 * __useProductIntegrityGetLabInfo__
 *
 * To run a query within a React component, call `useProductIntegrityGetLabInfo` and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityGetLabInfo` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductIntegrityGetLabInfo({
 *   variables: {
 *   },
 * });
 */
export function useProductIntegrityGetLabInfo(baseOptions?: Apollo.QueryHookOptions<Types.ProductIntegrityGetLabInfo, Types.ProductIntegrityGetLabInfoVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductIntegrityGetLabInfo, Types.ProductIntegrityGetLabInfoVariables>(ProductIntegrityGetLabInfoDocument, options);
      }
export function useProductIntegrityGetLabInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductIntegrityGetLabInfo, Types.ProductIntegrityGetLabInfoVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductIntegrityGetLabInfo, Types.ProductIntegrityGetLabInfoVariables>(ProductIntegrityGetLabInfoDocument, options);
        }
export type ProductIntegrityGetLabInfoHookResult = ReturnType<typeof useProductIntegrityGetLabInfo>;
export type ProductIntegrityGetLabInfoLazyQueryHookResult = ReturnType<typeof useProductIntegrityGetLabInfoLazyQuery>;
export type ProductIntegrityGetLabInfoQueryResult = Apollo.QueryResult<Types.ProductIntegrityGetLabInfo, Types.ProductIntegrityGetLabInfoVariables>;
export function refetchProductIntegrityGetLabInfo(variables?: Types.ProductIntegrityGetLabInfoVariables) {
      return { query: ProductIntegrityGetLabInfoDocument, variables: variables }
    }
export const ProductIntegrityGetRequestRegionsDocument = gql`
    query productIntegrityGetRequestRegions($requestId: String!) {
  productIntegrityGetRequestRegions(requestId: $requestId) {
    errors
    success
    results {
      checked
      name
      comment
      regionAttributes {
        name
        checked
      }
    }
  }
}
    `;

/**
 * __useProductIntegrityGetRequestRegions__
 *
 * To run a query within a React component, call `useProductIntegrityGetRequestRegions` and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityGetRequestRegions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductIntegrityGetRequestRegions({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useProductIntegrityGetRequestRegions(baseOptions: Apollo.QueryHookOptions<Types.ProductIntegrityGetRequestRegions, Types.ProductIntegrityGetRequestRegionsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductIntegrityGetRequestRegions, Types.ProductIntegrityGetRequestRegionsVariables>(ProductIntegrityGetRequestRegionsDocument, options);
      }
export function useProductIntegrityGetRequestRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductIntegrityGetRequestRegions, Types.ProductIntegrityGetRequestRegionsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductIntegrityGetRequestRegions, Types.ProductIntegrityGetRequestRegionsVariables>(ProductIntegrityGetRequestRegionsDocument, options);
        }
export type ProductIntegrityGetRequestRegionsHookResult = ReturnType<typeof useProductIntegrityGetRequestRegions>;
export type ProductIntegrityGetRequestRegionsLazyQueryHookResult = ReturnType<typeof useProductIntegrityGetRequestRegionsLazyQuery>;
export type ProductIntegrityGetRequestRegionsQueryResult = Apollo.QueryResult<Types.ProductIntegrityGetRequestRegions, Types.ProductIntegrityGetRequestRegionsVariables>;
export function refetchProductIntegrityGetRequestRegions(variables: Types.ProductIntegrityGetRequestRegionsVariables) {
      return { query: ProductIntegrityGetRequestRegionsDocument, variables: variables }
    }
export const ProductIntegrityCreateResultsDocument = gql`
    mutation productIntegrityCreateResults($input: ProductIntegrityCreateResultInput!) {
  productIntegrityCreateResults(links: $input) {
    errors
    success
  }
}
    `;
export type ProductIntegrityCreateResultsMutationFn = Apollo.MutationFunction<Types.ProductIntegrityCreateResults, Types.ProductIntegrityCreateResultsVariables>;

/**
 * __useProductIntegrityCreateResults__
 *
 * To run a mutation, you first call `useProductIntegrityCreateResults` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityCreateResults` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productIntegrityCreateResults, { data, loading, error }] = useProductIntegrityCreateResults({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductIntegrityCreateResults(baseOptions?: Apollo.MutationHookOptions<Types.ProductIntegrityCreateResults, Types.ProductIntegrityCreateResultsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductIntegrityCreateResults, Types.ProductIntegrityCreateResultsVariables>(ProductIntegrityCreateResultsDocument, options);
      }
export type ProductIntegrityCreateResultsHookResult = ReturnType<typeof useProductIntegrityCreateResults>;
export type ProductIntegrityCreateResultsMutationResult = Apollo.MutationResult<Types.ProductIntegrityCreateResults>;
export type ProductIntegrityCreateResultsMutationOptions = Apollo.BaseMutationOptions<Types.ProductIntegrityCreateResults, Types.ProductIntegrityCreateResultsVariables>;
export const ProductIntegrityRejectTestResultsDocument = gql`
    mutation productIntegrityRejectTestResults($requestNewTestsInput: ProductIntegrityRequestNewTestsInput!) {
  productIntegrityRejectTestResults(requestNewTestsInput: $requestNewTestsInput) {
    success
    errors
  }
}
    `;
export type ProductIntegrityRejectTestResultsMutationFn = Apollo.MutationFunction<Types.ProductIntegrityRejectTestResults, Types.ProductIntegrityRejectTestResultsVariables>;

/**
 * __useProductIntegrityRejectTestResults__
 *
 * To run a mutation, you first call `useProductIntegrityRejectTestResults` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityRejectTestResults` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productIntegrityRejectTestResults, { data, loading, error }] = useProductIntegrityRejectTestResults({
 *   variables: {
 *      requestNewTestsInput: // value for 'requestNewTestsInput'
 *   },
 * });
 */
export function useProductIntegrityRejectTestResults(baseOptions?: Apollo.MutationHookOptions<Types.ProductIntegrityRejectTestResults, Types.ProductIntegrityRejectTestResultsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductIntegrityRejectTestResults, Types.ProductIntegrityRejectTestResultsVariables>(ProductIntegrityRejectTestResultsDocument, options);
      }
export type ProductIntegrityRejectTestResultsHookResult = ReturnType<typeof useProductIntegrityRejectTestResults>;
export type ProductIntegrityRejectTestResultsMutationResult = Apollo.MutationResult<Types.ProductIntegrityRejectTestResults>;
export type ProductIntegrityRejectTestResultsMutationOptions = Apollo.BaseMutationOptions<Types.ProductIntegrityRejectTestResults, Types.ProductIntegrityRejectTestResultsVariables>;
export const ProductIntegritySubmitResultsApprovalDocument = gql`
    mutation productIntegritySubmitResultsApproval($result: ProductIntegritySubmitResultApprovalInput!) {
  productIntegritySubmitResultsApproval(result: $result) {
    success
    errors
  }
}
    `;
export type ProductIntegritySubmitResultsApprovalMutationFn = Apollo.MutationFunction<Types.ProductIntegritySubmitResultsApproval, Types.ProductIntegritySubmitResultsApprovalVariables>;

/**
 * __useProductIntegritySubmitResultsApproval__
 *
 * To run a mutation, you first call `useProductIntegritySubmitResultsApproval` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductIntegritySubmitResultsApproval` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productIntegritySubmitResultsApproval, { data, loading, error }] = useProductIntegritySubmitResultsApproval({
 *   variables: {
 *      result: // value for 'result'
 *   },
 * });
 */
export function useProductIntegritySubmitResultsApproval(baseOptions?: Apollo.MutationHookOptions<Types.ProductIntegritySubmitResultsApproval, Types.ProductIntegritySubmitResultsApprovalVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductIntegritySubmitResultsApproval, Types.ProductIntegritySubmitResultsApprovalVariables>(ProductIntegritySubmitResultsApprovalDocument, options);
      }
export type ProductIntegritySubmitResultsApprovalHookResult = ReturnType<typeof useProductIntegritySubmitResultsApproval>;
export type ProductIntegritySubmitResultsApprovalMutationResult = Apollo.MutationResult<Types.ProductIntegritySubmitResultsApproval>;
export type ProductIntegritySubmitResultsApprovalMutationOptions = Apollo.BaseMutationOptions<Types.ProductIntegritySubmitResultsApproval, Types.ProductIntegritySubmitResultsApprovalVariables>;
export const ProductIntegrityCreateQuoteApprovalLinkObjectDocument = gql`
    mutation ProductIntegrityCreateQuoteApprovalLinkObject($input: ProductIntegrityQuoteApprovalLinkInputV2!) {
  productIntegrityCreateQuoteApprovalLinkObject(links: $input) {
    errors
    success
  }
}
    `;
export type ProductIntegrityCreateQuoteApprovalLinkObjectMutationFn = Apollo.MutationFunction<Types.ProductIntegrityCreateQuoteApprovalLinkObject, Types.ProductIntegrityCreateQuoteApprovalLinkObjectVariables>;

/**
 * __useProductIntegrityCreateQuoteApprovalLinkObject__
 *
 * To run a mutation, you first call `useProductIntegrityCreateQuoteApprovalLinkObject` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityCreateQuoteApprovalLinkObject` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productIntegrityCreateQuoteApprovalLinkObject, { data, loading, error }] = useProductIntegrityCreateQuoteApprovalLinkObject({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductIntegrityCreateQuoteApprovalLinkObject(baseOptions?: Apollo.MutationHookOptions<Types.ProductIntegrityCreateQuoteApprovalLinkObject, Types.ProductIntegrityCreateQuoteApprovalLinkObjectVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductIntegrityCreateQuoteApprovalLinkObject, Types.ProductIntegrityCreateQuoteApprovalLinkObjectVariables>(ProductIntegrityCreateQuoteApprovalLinkObjectDocument, options);
      }
export type ProductIntegrityCreateQuoteApprovalLinkObjectHookResult = ReturnType<typeof useProductIntegrityCreateQuoteApprovalLinkObject>;
export type ProductIntegrityCreateQuoteApprovalLinkObjectMutationResult = Apollo.MutationResult<Types.ProductIntegrityCreateQuoteApprovalLinkObject>;
export type ProductIntegrityCreateQuoteApprovalLinkObjectMutationOptions = Apollo.BaseMutationOptions<Types.ProductIntegrityCreateQuoteApprovalLinkObject, Types.ProductIntegrityCreateQuoteApprovalLinkObjectVariables>;
export const ProductIntegrityRequestNewQuotesDocument = gql`
    mutation productIntegrityRequestNewQuotes($requestNewQuotesInput: ProductIntegrityRequestNewQuotesInput!) {
  productIntegrityRequestNewQuotes(requestNewQuotesInput: $requestNewQuotesInput) {
    success
    errors
  }
}
    `;
export type ProductIntegrityRequestNewQuotesMutationFn = Apollo.MutationFunction<Types.ProductIntegrityRequestNewQuotes, Types.ProductIntegrityRequestNewQuotesVariables>;

/**
 * __useProductIntegrityRequestNewQuotes__
 *
 * To run a mutation, you first call `useProductIntegrityRequestNewQuotes` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityRequestNewQuotes` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productIntegrityRequestNewQuotes, { data, loading, error }] = useProductIntegrityRequestNewQuotes({
 *   variables: {
 *      requestNewQuotesInput: // value for 'requestNewQuotesInput'
 *   },
 * });
 */
export function useProductIntegrityRequestNewQuotes(baseOptions?: Apollo.MutationHookOptions<Types.ProductIntegrityRequestNewQuotes, Types.ProductIntegrityRequestNewQuotesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductIntegrityRequestNewQuotes, Types.ProductIntegrityRequestNewQuotesVariables>(ProductIntegrityRequestNewQuotesDocument, options);
      }
export type ProductIntegrityRequestNewQuotesHookResult = ReturnType<typeof useProductIntegrityRequestNewQuotes>;
export type ProductIntegrityRequestNewQuotesMutationResult = Apollo.MutationResult<Types.ProductIntegrityRequestNewQuotes>;
export type ProductIntegrityRequestNewQuotesMutationOptions = Apollo.BaseMutationOptions<Types.ProductIntegrityRequestNewQuotes, Types.ProductIntegrityRequestNewQuotesVariables>;
export const ProductIntegritySubmitQuotesAcknowledgeDocument = gql`
    mutation productIntegritySubmitQuotesAcknowledge($quotes: ProductIntegritySubmitQuotesAcknowledgeInput!) {
  productIntegritySubmitQuotesAcknowledge(quotes: $quotes) {
    success
    errors
  }
}
    `;
export type ProductIntegritySubmitQuotesAcknowledgeMutationFn = Apollo.MutationFunction<Types.ProductIntegritySubmitQuotesAcknowledge, Types.ProductIntegritySubmitQuotesAcknowledgeVariables>;

/**
 * __useProductIntegritySubmitQuotesAcknowledge__
 *
 * To run a mutation, you first call `useProductIntegritySubmitQuotesAcknowledge` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductIntegritySubmitQuotesAcknowledge` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productIntegritySubmitQuotesAcknowledge, { data, loading, error }] = useProductIntegritySubmitQuotesAcknowledge({
 *   variables: {
 *      quotes: // value for 'quotes'
 *   },
 * });
 */
export function useProductIntegritySubmitQuotesAcknowledge(baseOptions?: Apollo.MutationHookOptions<Types.ProductIntegritySubmitQuotesAcknowledge, Types.ProductIntegritySubmitQuotesAcknowledgeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductIntegritySubmitQuotesAcknowledge, Types.ProductIntegritySubmitQuotesAcknowledgeVariables>(ProductIntegritySubmitQuotesAcknowledgeDocument, options);
      }
export type ProductIntegritySubmitQuotesAcknowledgeHookResult = ReturnType<typeof useProductIntegritySubmitQuotesAcknowledge>;
export type ProductIntegritySubmitQuotesAcknowledgeMutationResult = Apollo.MutationResult<Types.ProductIntegritySubmitQuotesAcknowledge>;
export type ProductIntegritySubmitQuotesAcknowledgeMutationOptions = Apollo.BaseMutationOptions<Types.ProductIntegritySubmitQuotesAcknowledge, Types.ProductIntegritySubmitQuotesAcknowledgeVariables>;
export const ProductIntegritySubmitQuotesApprovalDocument = gql`
    mutation productIntegritySubmitQuotesApproval($quotes: ProductIntegritySubmitQuotesApprovalInput!) {
  productIntegritySubmitQuotesApproval(quotes: $quotes) {
    success
    errors
  }
}
    `;
export type ProductIntegritySubmitQuotesApprovalMutationFn = Apollo.MutationFunction<Types.ProductIntegritySubmitQuotesApproval, Types.ProductIntegritySubmitQuotesApprovalVariables>;

/**
 * __useProductIntegritySubmitQuotesApproval__
 *
 * To run a mutation, you first call `useProductIntegritySubmitQuotesApproval` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductIntegritySubmitQuotesApproval` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productIntegritySubmitQuotesApproval, { data, loading, error }] = useProductIntegritySubmitQuotesApproval({
 *   variables: {
 *      quotes: // value for 'quotes'
 *   },
 * });
 */
export function useProductIntegritySubmitQuotesApproval(baseOptions?: Apollo.MutationHookOptions<Types.ProductIntegritySubmitQuotesApproval, Types.ProductIntegritySubmitQuotesApprovalVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductIntegritySubmitQuotesApproval, Types.ProductIntegritySubmitQuotesApprovalVariables>(ProductIntegritySubmitQuotesApprovalDocument, options);
      }
export type ProductIntegritySubmitQuotesApprovalHookResult = ReturnType<typeof useProductIntegritySubmitQuotesApproval>;
export type ProductIntegritySubmitQuotesApprovalMutationResult = Apollo.MutationResult<Types.ProductIntegritySubmitQuotesApproval>;
export type ProductIntegritySubmitQuotesApprovalMutationOptions = Apollo.BaseMutationOptions<Types.ProductIntegritySubmitQuotesApproval, Types.ProductIntegritySubmitQuotesApprovalVariables>;
export const ProductIntegritySubmitQuotesApprovalOnlyDocument = gql`
    mutation productIntegritySubmitQuotesApprovalOnly($quotes: ProductIntegritySubmitQuotesApprovalInput!) {
  productIntegritySubmitQuotesApprovalOnly(quotes: $quotes) {
    success
    errors
  }
}
    `;
export type ProductIntegritySubmitQuotesApprovalOnlyMutationFn = Apollo.MutationFunction<Types.ProductIntegritySubmitQuotesApprovalOnly, Types.ProductIntegritySubmitQuotesApprovalOnlyVariables>;

/**
 * __useProductIntegritySubmitQuotesApprovalOnly__
 *
 * To run a mutation, you first call `useProductIntegritySubmitQuotesApprovalOnly` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductIntegritySubmitQuotesApprovalOnly` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productIntegritySubmitQuotesApprovalOnly, { data, loading, error }] = useProductIntegritySubmitQuotesApprovalOnly({
 *   variables: {
 *      quotes: // value for 'quotes'
 *   },
 * });
 */
export function useProductIntegritySubmitQuotesApprovalOnly(baseOptions?: Apollo.MutationHookOptions<Types.ProductIntegritySubmitQuotesApprovalOnly, Types.ProductIntegritySubmitQuotesApprovalOnlyVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductIntegritySubmitQuotesApprovalOnly, Types.ProductIntegritySubmitQuotesApprovalOnlyVariables>(ProductIntegritySubmitQuotesApprovalOnlyDocument, options);
      }
export type ProductIntegritySubmitQuotesApprovalOnlyHookResult = ReturnType<typeof useProductIntegritySubmitQuotesApprovalOnly>;
export type ProductIntegritySubmitQuotesApprovalOnlyMutationResult = Apollo.MutationResult<Types.ProductIntegritySubmitQuotesApprovalOnly>;
export type ProductIntegritySubmitQuotesApprovalOnlyMutationOptions = Apollo.BaseMutationOptions<Types.ProductIntegritySubmitQuotesApprovalOnly, Types.ProductIntegritySubmitQuotesApprovalOnlyVariables>;
export const ProductIntegrityProductCategoriesDocument = gql`
    query productIntegrityProductCategories {
  productIntegrityProductCategories {
    results
    errors
    success
  }
}
    `;

/**
 * __useProductIntegrityProductCategories__
 *
 * To run a query within a React component, call `useProductIntegrityProductCategories` and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityProductCategories` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductIntegrityProductCategories({
 *   variables: {
 *   },
 * });
 */
export function useProductIntegrityProductCategories(baseOptions?: Apollo.QueryHookOptions<Types.ProductIntegrityProductCategories, Types.ProductIntegrityProductCategoriesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductIntegrityProductCategories, Types.ProductIntegrityProductCategoriesVariables>(ProductIntegrityProductCategoriesDocument, options);
      }
export function useProductIntegrityProductCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductIntegrityProductCategories, Types.ProductIntegrityProductCategoriesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductIntegrityProductCategories, Types.ProductIntegrityProductCategoriesVariables>(ProductIntegrityProductCategoriesDocument, options);
        }
export type ProductIntegrityProductCategoriesHookResult = ReturnType<typeof useProductIntegrityProductCategories>;
export type ProductIntegrityProductCategoriesLazyQueryHookResult = ReturnType<typeof useProductIntegrityProductCategoriesLazyQuery>;
export type ProductIntegrityProductCategoriesQueryResult = Apollo.QueryResult<Types.ProductIntegrityProductCategories, Types.ProductIntegrityProductCategoriesVariables>;
export function refetchProductIntegrityProductCategories(variables?: Types.ProductIntegrityProductCategoriesVariables) {
      return { query: ProductIntegrityProductCategoriesDocument, variables: variables }
    }
export const ProductIntegrityDropRequestDocument = gql`
    mutation productIntegrityDropRequest($id: String!, $userEmail: String!) {
  productIntegrityDropRequest(id: $id, userEmail: $userEmail) {
    errors
    success
  }
}
    `;
export type ProductIntegrityDropRequestMutationFn = Apollo.MutationFunction<Types.ProductIntegrityDropRequest, Types.ProductIntegrityDropRequestVariables>;

/**
 * __useProductIntegrityDropRequest__
 *
 * To run a mutation, you first call `useProductIntegrityDropRequest` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityDropRequest` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productIntegrityDropRequest, { data, loading, error }] = useProductIntegrityDropRequest({
 *   variables: {
 *      id: // value for 'id'
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useProductIntegrityDropRequest(baseOptions?: Apollo.MutationHookOptions<Types.ProductIntegrityDropRequest, Types.ProductIntegrityDropRequestVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductIntegrityDropRequest, Types.ProductIntegrityDropRequestVariables>(ProductIntegrityDropRequestDocument, options);
      }
export type ProductIntegrityDropRequestHookResult = ReturnType<typeof useProductIntegrityDropRequest>;
export type ProductIntegrityDropRequestMutationResult = Apollo.MutationResult<Types.ProductIntegrityDropRequest>;
export type ProductIntegrityDropRequestMutationOptions = Apollo.BaseMutationOptions<Types.ProductIntegrityDropRequest, Types.ProductIntegrityDropRequestVariables>;
export const ProductIntegrityReassignPimDocument = gql`
    mutation ProductIntegrityReassignPim($input: ProductIntegrityReAssignPIMInput!) {
  productIntegrityReassignPim(input: $input) {
    error
    success
  }
}
    `;
export type ProductIntegrityReassignPimMutationFn = Apollo.MutationFunction<Types.ProductIntegrityReassignPim, Types.ProductIntegrityReassignPimVariables>;

/**
 * __useProductIntegrityReassignPim__
 *
 * To run a mutation, you first call `useProductIntegrityReassignPim` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityReassignPim` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productIntegrityReassignPim, { data, loading, error }] = useProductIntegrityReassignPim({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductIntegrityReassignPim(baseOptions?: Apollo.MutationHookOptions<Types.ProductIntegrityReassignPim, Types.ProductIntegrityReassignPimVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductIntegrityReassignPim, Types.ProductIntegrityReassignPimVariables>(ProductIntegrityReassignPimDocument, options);
      }
export type ProductIntegrityReassignPimHookResult = ReturnType<typeof useProductIntegrityReassignPim>;
export type ProductIntegrityReassignPimMutationResult = Apollo.MutationResult<Types.ProductIntegrityReassignPim>;
export type ProductIntegrityReassignPimMutationOptions = Apollo.BaseMutationOptions<Types.ProductIntegrityReassignPim, Types.ProductIntegrityReassignPimVariables>;
export const ProductIntegrityRelatedChildAsinsDocument = gql`
    query productIntegrityRelatedChildAsins($childAsin: String!) {
  productIntegrityRelatedChildAsins(childAsin: $childAsin) {
    errors
    results
    success
  }
}
    `;

/**
 * __useProductIntegrityRelatedChildAsins__
 *
 * To run a query within a React component, call `useProductIntegrityRelatedChildAsins` and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityRelatedChildAsins` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductIntegrityRelatedChildAsins({
 *   variables: {
 *      childAsin: // value for 'childAsin'
 *   },
 * });
 */
export function useProductIntegrityRelatedChildAsins(baseOptions: Apollo.QueryHookOptions<Types.ProductIntegrityRelatedChildAsins, Types.ProductIntegrityRelatedChildAsinsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductIntegrityRelatedChildAsins, Types.ProductIntegrityRelatedChildAsinsVariables>(ProductIntegrityRelatedChildAsinsDocument, options);
      }
export function useProductIntegrityRelatedChildAsinsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductIntegrityRelatedChildAsins, Types.ProductIntegrityRelatedChildAsinsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductIntegrityRelatedChildAsins, Types.ProductIntegrityRelatedChildAsinsVariables>(ProductIntegrityRelatedChildAsinsDocument, options);
        }
export type ProductIntegrityRelatedChildAsinsHookResult = ReturnType<typeof useProductIntegrityRelatedChildAsins>;
export type ProductIntegrityRelatedChildAsinsLazyQueryHookResult = ReturnType<typeof useProductIntegrityRelatedChildAsinsLazyQuery>;
export type ProductIntegrityRelatedChildAsinsQueryResult = Apollo.QueryResult<Types.ProductIntegrityRelatedChildAsins, Types.ProductIntegrityRelatedChildAsinsVariables>;
export function refetchProductIntegrityRelatedChildAsins(variables: Types.ProductIntegrityRelatedChildAsinsVariables) {
      return { query: ProductIntegrityRelatedChildAsinsDocument, variables: variables }
    }
export const ProductIntegrityCancelRequestDocument = gql`
    mutation productIntegrityCancelRequest($id: String!, $userEmail: String!) {
  productIntegrityCancelRequest(id: $id, userEmail: $userEmail) {
    errors
    success
  }
}
    `;
export type ProductIntegrityCancelRequestMutationFn = Apollo.MutationFunction<Types.ProductIntegrityCancelRequest, Types.ProductIntegrityCancelRequestVariables>;

/**
 * __useProductIntegrityCancelRequest__
 *
 * To run a mutation, you first call `useProductIntegrityCancelRequest` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityCancelRequest` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productIntegrityCancelRequest, { data, loading, error }] = useProductIntegrityCancelRequest({
 *   variables: {
 *      id: // value for 'id'
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useProductIntegrityCancelRequest(baseOptions?: Apollo.MutationHookOptions<Types.ProductIntegrityCancelRequest, Types.ProductIntegrityCancelRequestVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductIntegrityCancelRequest, Types.ProductIntegrityCancelRequestVariables>(ProductIntegrityCancelRequestDocument, options);
      }
export type ProductIntegrityCancelRequestHookResult = ReturnType<typeof useProductIntegrityCancelRequest>;
export type ProductIntegrityCancelRequestMutationResult = Apollo.MutationResult<Types.ProductIntegrityCancelRequest>;
export type ProductIntegrityCancelRequestMutationOptions = Apollo.BaseMutationOptions<Types.ProductIntegrityCancelRequest, Types.ProductIntegrityCancelRequestVariables>;
export const ProductIntegrityCreateRequestV3Document = gql`
    mutation productIntegrityCreateRequestV3($input: ProductIntegrityRequestInputV3!) {
  productIntegrityCreateRequestV3(request: $input) {
    errors
    success
  }
}
    `;
export type ProductIntegrityCreateRequestV3MutationFn = Apollo.MutationFunction<Types.ProductIntegrityCreateRequestV3, Types.ProductIntegrityCreateRequestV3Variables>;

/**
 * __useProductIntegrityCreateRequestV3__
 *
 * To run a mutation, you first call `useProductIntegrityCreateRequestV3` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityCreateRequestV3` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productIntegrityCreateRequestV3, { data, loading, error }] = useProductIntegrityCreateRequestV3({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductIntegrityCreateRequestV3(baseOptions?: Apollo.MutationHookOptions<Types.ProductIntegrityCreateRequestV3, Types.ProductIntegrityCreateRequestV3Variables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductIntegrityCreateRequestV3, Types.ProductIntegrityCreateRequestV3Variables>(ProductIntegrityCreateRequestV3Document, options);
      }
export type ProductIntegrityCreateRequestV3HookResult = ReturnType<typeof useProductIntegrityCreateRequestV3>;
export type ProductIntegrityCreateRequestV3MutationResult = Apollo.MutationResult<Types.ProductIntegrityCreateRequestV3>;
export type ProductIntegrityCreateRequestV3MutationOptions = Apollo.BaseMutationOptions<Types.ProductIntegrityCreateRequestV3, Types.ProductIntegrityCreateRequestV3Variables>;
export const ProductIntegrityRequestsDocument = gql`
    query ProductIntegrityRequests($filter: RequestFilter, $pagination: Pagination, $sort: [SortDescriptor!]) {
  productIntegrityRequests(filter: $filter, pagination: $pagination, sort: $sort) {
    results {
      id
      status
      asin
      brand
      testNeededByDate
      chinaProductIntegrityManager
      usProductIntegrityManager
      chinaQa
      legalName
      uniqueId
      quoteApprovalOnly
      usAssignedPim
      chinaAssignedPim
      requesterName
      requesterFunction
    }
    totalResults
  }
}
    `;

/**
 * __useProductIntegrityRequests__
 *
 * To run a query within a React component, call `useProductIntegrityRequests` and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityRequests` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductIntegrityRequests({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useProductIntegrityRequests(baseOptions?: Apollo.QueryHookOptions<Types.ProductIntegrityRequests, Types.ProductIntegrityRequestsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductIntegrityRequests, Types.ProductIntegrityRequestsVariables>(ProductIntegrityRequestsDocument, options);
      }
export function useProductIntegrityRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductIntegrityRequests, Types.ProductIntegrityRequestsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductIntegrityRequests, Types.ProductIntegrityRequestsVariables>(ProductIntegrityRequestsDocument, options);
        }
export type ProductIntegrityRequestsHookResult = ReturnType<typeof useProductIntegrityRequests>;
export type ProductIntegrityRequestsLazyQueryHookResult = ReturnType<typeof useProductIntegrityRequestsLazyQuery>;
export type ProductIntegrityRequestsQueryResult = Apollo.QueryResult<Types.ProductIntegrityRequests, Types.ProductIntegrityRequestsVariables>;
export function refetchProductIntegrityRequests(variables?: Types.ProductIntegrityRequestsVariables) {
      return { query: ProductIntegrityRequestsDocument, variables: variables }
    }
export const ReplenishmentRequestCsvUploadDocument = gql`
    mutation ReplenishmentRequestCSVUpload($input: CSVUploadInput!) {
  replenishmentRequestCsvUpload(input: $input) {
    success
    errors
  }
}
    `;
export type ReplenishmentRequestCsvUploadMutationFn = Apollo.MutationFunction<Types.ReplenishmentRequestCsvUpload, Types.ReplenishmentRequestCsvUploadVariables>;

/**
 * __useReplenishmentRequestCsvUpload__
 *
 * To run a mutation, you first call `useReplenishmentRequestCsvUpload` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplenishmentRequestCsvUpload` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replenishmentRequestCsvUpload, { data, loading, error }] = useReplenishmentRequestCsvUpload({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReplenishmentRequestCsvUpload(baseOptions?: Apollo.MutationHookOptions<Types.ReplenishmentRequestCsvUpload, Types.ReplenishmentRequestCsvUploadVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ReplenishmentRequestCsvUpload, Types.ReplenishmentRequestCsvUploadVariables>(ReplenishmentRequestCsvUploadDocument, options);
      }
export type ReplenishmentRequestCsvUploadHookResult = ReturnType<typeof useReplenishmentRequestCsvUpload>;
export type ReplenishmentRequestCsvUploadMutationResult = Apollo.MutationResult<Types.ReplenishmentRequestCsvUpload>;
export type ReplenishmentRequestCsvUploadMutationOptions = Apollo.BaseMutationOptions<Types.ReplenishmentRequestCsvUpload, Types.ReplenishmentRequestCsvUploadVariables>;
export const RequestReplenishmentUploadUrlDocument = gql`
    mutation RequestReplenishmentUploadUrl {
  requestReplenishmentUploadUrl {
    ...signedUploadFragment
  }
}
    ${SignedUploadFragment}`;
export type RequestReplenishmentUploadUrlMutationFn = Apollo.MutationFunction<Types.RequestReplenishmentUploadUrl, Types.RequestReplenishmentUploadUrlVariables>;

/**
 * __useRequestReplenishmentUploadUrl__
 *
 * To run a mutation, you first call `useRequestReplenishmentUploadUrl` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestReplenishmentUploadUrl` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestReplenishmentUploadUrl, { data, loading, error }] = useRequestReplenishmentUploadUrl({
 *   variables: {
 *   },
 * });
 */
export function useRequestReplenishmentUploadUrl(baseOptions?: Apollo.MutationHookOptions<Types.RequestReplenishmentUploadUrl, Types.RequestReplenishmentUploadUrlVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestReplenishmentUploadUrl, Types.RequestReplenishmentUploadUrlVariables>(RequestReplenishmentUploadUrlDocument, options);
      }
export type RequestReplenishmentUploadUrlHookResult = ReturnType<typeof useRequestReplenishmentUploadUrl>;
export type RequestReplenishmentUploadUrlMutationResult = Apollo.MutationResult<Types.RequestReplenishmentUploadUrl>;
export type RequestReplenishmentUploadUrlMutationOptions = Apollo.BaseMutationOptions<Types.RequestReplenishmentUploadUrl, Types.RequestReplenishmentUploadUrlVariables>;
export const PlannerRequestSkuListingDocument = gql`
    query PlannerRequestSkuListing($input: PlannerRequestInfoInput!) {
  plannerRequestSkuListing(input: $input) {
    success
    errors
    skuList {
      nsSku
      skuType
      tplSku
      tplLocation
      tplStatus
      tplOhQuantity
    }
  }
}
    `;

/**
 * __usePlannerRequestSkuListing__
 *
 * To run a query within a React component, call `usePlannerRequestSkuListing` and pass it any options that fit your needs.
 * When your component renders, `usePlannerRequestSkuListing` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlannerRequestSkuListing({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlannerRequestSkuListing(baseOptions: Apollo.QueryHookOptions<Types.PlannerRequestSkuListing, Types.PlannerRequestSkuListingVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PlannerRequestSkuListing, Types.PlannerRequestSkuListingVariables>(PlannerRequestSkuListingDocument, options);
      }
export function usePlannerRequestSkuListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PlannerRequestSkuListing, Types.PlannerRequestSkuListingVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PlannerRequestSkuListing, Types.PlannerRequestSkuListingVariables>(PlannerRequestSkuListingDocument, options);
        }
export type PlannerRequestSkuListingHookResult = ReturnType<typeof usePlannerRequestSkuListing>;
export type PlannerRequestSkuListingLazyQueryHookResult = ReturnType<typeof usePlannerRequestSkuListingLazyQuery>;
export type PlannerRequestSkuListingQueryResult = Apollo.QueryResult<Types.PlannerRequestSkuListing, Types.PlannerRequestSkuListingVariables>;
export function refetchPlannerRequestSkuListing(variables: Types.PlannerRequestSkuListingVariables) {
      return { query: PlannerRequestSkuListingDocument, variables: variables }
    }
export const PlannerRequestUpdateDocument = gql`
    mutation plannerRequestUpdate($input: PlannerRequestUpdateInput!) {
  plannerRequestUpdate(input: $input) {
    success
    errors
    requests {
      status
      issueStatus
      sellerCentralAccount
      notes
      issue
      confirmedQuantity
    }
  }
}
    `;
export type PlannerRequestUpdateMutationFn = Apollo.MutationFunction<Types.PlannerRequestUpdate, Types.PlannerRequestUpdateVariables>;

/**
 * __usePlannerRequestUpdate__
 *
 * To run a mutation, you first call `usePlannerRequestUpdate` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlannerRequestUpdate` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [plannerRequestUpdate, { data, loading, error }] = usePlannerRequestUpdate({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlannerRequestUpdate(baseOptions?: Apollo.MutationHookOptions<Types.PlannerRequestUpdate, Types.PlannerRequestUpdateVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PlannerRequestUpdate, Types.PlannerRequestUpdateVariables>(PlannerRequestUpdateDocument, options);
      }
export type PlannerRequestUpdateHookResult = ReturnType<typeof usePlannerRequestUpdate>;
export type PlannerRequestUpdateMutationResult = Apollo.MutationResult<Types.PlannerRequestUpdate>;
export type PlannerRequestUpdateMutationOptions = Apollo.BaseMutationOptions<Types.PlannerRequestUpdate, Types.PlannerRequestUpdateVariables>;
export const IrisGetPackagingInfoDocument = gql`
    query IrisGetPackagingInfo($input: GetPackagingInfoInput!) {
  irisGetPackagingInfo(input: $input) {
    errors
    palletSize
    success
    casePack
  }
}
    `;

/**
 * __useIrisGetPackagingInfo__
 *
 * To run a query within a React component, call `useIrisGetPackagingInfo` and pass it any options that fit your needs.
 * When your component renders, `useIrisGetPackagingInfo` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIrisGetPackagingInfo({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIrisGetPackagingInfo(baseOptions: Apollo.QueryHookOptions<Types.IrisGetPackagingInfo, Types.IrisGetPackagingInfoVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IrisGetPackagingInfo, Types.IrisGetPackagingInfoVariables>(IrisGetPackagingInfoDocument, options);
      }
export function useIrisGetPackagingInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IrisGetPackagingInfo, Types.IrisGetPackagingInfoVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IrisGetPackagingInfo, Types.IrisGetPackagingInfoVariables>(IrisGetPackagingInfoDocument, options);
        }
export type IrisGetPackagingInfoHookResult = ReturnType<typeof useIrisGetPackagingInfo>;
export type IrisGetPackagingInfoLazyQueryHookResult = ReturnType<typeof useIrisGetPackagingInfoLazyQuery>;
export type IrisGetPackagingInfoQueryResult = Apollo.QueryResult<Types.IrisGetPackagingInfo, Types.IrisGetPackagingInfoVariables>;
export function refetchIrisGetPackagingInfo(variables: Types.IrisGetPackagingInfoVariables) {
      return { query: IrisGetPackagingInfoDocument, variables: variables }
    }
export const IssuesQueryDocument = gql`
    query IssuesQuery($filter: RequestFilter, $pagination: Pagination, $sort: [SortDescriptor!]) {
  issuesQuery(filter: $filter, pagination: $pagination, sort: $sort) {
    results {
      issues
      issueDescription
      id
      active
    }
    totalResults
  }
}
    `;

/**
 * __useIssuesQuery__
 *
 * To run a query within a React component, call `useIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssuesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useIssuesQuery(baseOptions?: Apollo.QueryHookOptions<Types.IssuesQuery, Types.IssuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IssuesQuery, Types.IssuesQueryVariables>(IssuesQueryDocument, options);
      }
export function useIssuesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IssuesQuery, Types.IssuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IssuesQuery, Types.IssuesQueryVariables>(IssuesQueryDocument, options);
        }
export type IssuesQueryHookResult = ReturnType<typeof useIssuesQuery>;
export type IssuesQueryLazyQueryHookResult = ReturnType<typeof useIssuesQueryLazyQuery>;
export type IssuesQueryQueryResult = Apollo.QueryResult<Types.IssuesQuery, Types.IssuesQueryVariables>;
export function refetchIssuesQuery(variables?: Types.IssuesQueryVariables) {
      return { query: IssuesQueryDocument, variables: variables }
    }
export const PlannerRequestCancelDocument = gql`
    mutation plannerRequestCancel($id: String!, $userEmail: String!) {
  plannerRequestCancel(id: $id, userEmail: $userEmail) {
    errors
    success
  }
}
    `;
export type PlannerRequestCancelMutationFn = Apollo.MutationFunction<Types.PlannerRequestCancel, Types.PlannerRequestCancelVariables>;

/**
 * __usePlannerRequestCancel__
 *
 * To run a mutation, you first call `usePlannerRequestCancel` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlannerRequestCancel` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [plannerRequestCancel, { data, loading, error }] = usePlannerRequestCancel({
 *   variables: {
 *      id: // value for 'id'
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function usePlannerRequestCancel(baseOptions?: Apollo.MutationHookOptions<Types.PlannerRequestCancel, Types.PlannerRequestCancelVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PlannerRequestCancel, Types.PlannerRequestCancelVariables>(PlannerRequestCancelDocument, options);
      }
export type PlannerRequestCancelHookResult = ReturnType<typeof usePlannerRequestCancel>;
export type PlannerRequestCancelMutationResult = Apollo.MutationResult<Types.PlannerRequestCancel>;
export type PlannerRequestCancelMutationOptions = Apollo.BaseMutationOptions<Types.PlannerRequestCancel, Types.PlannerRequestCancelVariables>;
export const PlannerRequestCreateV2Document = gql`
    mutation PlannerRequestCreateV2($input: PlannerRequestInputV2!) {
  plannerRequestCreateV2(input: $input) {
    errors
    success
    requests {
      arriveByDate
      asin
      brand
      coordinatorEmail
      coordinatorName
      confirmedQuantity
      multiChannelFulfillment
      notes
      plannerEmail
      plannerName
      priority
      quantity
      region
      sellerCentralAccount
      status
      uniqueId
      isExpedite
      expediteReason
    }
  }
}
    `;
export type PlannerRequestCreateV2MutationFn = Apollo.MutationFunction<Types.PlannerRequestCreateV2, Types.PlannerRequestCreateV2Variables>;

/**
 * __usePlannerRequestCreateV2__
 *
 * To run a mutation, you first call `usePlannerRequestCreateV2` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlannerRequestCreateV2` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [plannerRequestCreateV2, { data, loading, error }] = usePlannerRequestCreateV2({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlannerRequestCreateV2(baseOptions?: Apollo.MutationHookOptions<Types.PlannerRequestCreateV2, Types.PlannerRequestCreateV2Variables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PlannerRequestCreateV2, Types.PlannerRequestCreateV2Variables>(PlannerRequestCreateV2Document, options);
      }
export type PlannerRequestCreateV2HookResult = ReturnType<typeof usePlannerRequestCreateV2>;
export type PlannerRequestCreateV2MutationResult = Apollo.MutationResult<Types.PlannerRequestCreateV2>;
export type PlannerRequestCreateV2MutationOptions = Apollo.BaseMutationOptions<Types.PlannerRequestCreateV2, Types.PlannerRequestCreateV2Variables>;
export const PlannerRequestsQueryDocument = gql`
    query PlannerRequestsQuery($filter: RequestFilter, $pagination: Pagination, $sort: [SortDescriptor!]) {
  plannerRequestsQuery(filter: $filter, pagination: $pagination, sort: $sort) {
    results {
      uniqueId
      arriveByDate
      asin
      brand
      coordinatorEmail
      coordinatorName
      confirmedQuantity
      createdOn
      id
      multiChannelFulfillment
      notes
      plannerEmail
      plannerName
      priority
      quantity
      region
      sellerCentralAccount
      status
      updatedOn
      issue
      issueStatus
      issueName
      fbaIds {
        fbaId
        transferOrders
        quantity
        barcodeLink
      }
      amzDaysOfSupply
      orderByDate
      isExpedite
      expediteReason
      queenSku
      listingSku
      fromWarehouse
    }
    totalResults
  }
}
    `;

/**
 * __usePlannerRequestsQuery__
 *
 * To run a query within a React component, call `usePlannerRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlannerRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlannerRequestsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function usePlannerRequestsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PlannerRequestsQuery, Types.PlannerRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PlannerRequestsQuery, Types.PlannerRequestsQueryVariables>(PlannerRequestsQueryDocument, options);
      }
export function usePlannerRequestsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PlannerRequestsQuery, Types.PlannerRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PlannerRequestsQuery, Types.PlannerRequestsQueryVariables>(PlannerRequestsQueryDocument, options);
        }
export type PlannerRequestsQueryHookResult = ReturnType<typeof usePlannerRequestsQuery>;
export type PlannerRequestsQueryLazyQueryHookResult = ReturnType<typeof usePlannerRequestsQueryLazyQuery>;
export type PlannerRequestsQueryQueryResult = Apollo.QueryResult<Types.PlannerRequestsQuery, Types.PlannerRequestsQueryVariables>;
export function refetchPlannerRequestsQuery(variables?: Types.PlannerRequestsQueryVariables) {
      return { query: PlannerRequestsQueryDocument, variables: variables }
    }
export const AssignTpidToChannelKeyDocument = gql`
    mutation assignTpidToChannelKey($input: AssignTPIDToChannelKeyInput!, $userEmail: String!) {
  assignTpidToChannelKey(input: $input, userEmail: $userEmail) {
    results {
      channelKey
      oldTpid
      message
      success
    }
    newTpidValue
  }
}
    `;
export type AssignTpidToChannelKeyMutationFn = Apollo.MutationFunction<Types.AssignTpidToChannelKey, Types.AssignTpidToChannelKeyVariables>;

/**
 * __useAssignTpidToChannelKey__
 *
 * To run a mutation, you first call `useAssignTpidToChannelKey` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTpidToChannelKey` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTpidToChannelKey, { data, loading, error }] = useAssignTpidToChannelKey({
 *   variables: {
 *      input: // value for 'input'
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useAssignTpidToChannelKey(baseOptions?: Apollo.MutationHookOptions<Types.AssignTpidToChannelKey, Types.AssignTpidToChannelKeyVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AssignTpidToChannelKey, Types.AssignTpidToChannelKeyVariables>(AssignTpidToChannelKeyDocument, options);
      }
export type AssignTpidToChannelKeyHookResult = ReturnType<typeof useAssignTpidToChannelKey>;
export type AssignTpidToChannelKeyMutationResult = Apollo.MutationResult<Types.AssignTpidToChannelKey>;
export type AssignTpidToChannelKeyMutationOptions = Apollo.BaseMutationOptions<Types.AssignTpidToChannelKey, Types.AssignTpidToChannelKeyVariables>;
export const GetChannelIdsFromListingsDocument = gql`
    query getChannelIdsFromListings($filter: RequestFilter!) {
  listingsForTPID(filter: $filter) {
    results {
      channelid
    }
    totalResults
  }
}
    `;

/**
 * __useGetChannelIdsFromListings__
 *
 * To run a query within a React component, call `useGetChannelIdsFromListings` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelIdsFromListings` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelIdsFromListings({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetChannelIdsFromListings(baseOptions: Apollo.QueryHookOptions<Types.GetChannelIdsFromListings, Types.GetChannelIdsFromListingsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetChannelIdsFromListings, Types.GetChannelIdsFromListingsVariables>(GetChannelIdsFromListingsDocument, options);
      }
export function useGetChannelIdsFromListingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChannelIdsFromListings, Types.GetChannelIdsFromListingsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetChannelIdsFromListings, Types.GetChannelIdsFromListingsVariables>(GetChannelIdsFromListingsDocument, options);
        }
export type GetChannelIdsFromListingsHookResult = ReturnType<typeof useGetChannelIdsFromListings>;
export type GetChannelIdsFromListingsLazyQueryHookResult = ReturnType<typeof useGetChannelIdsFromListingsLazyQuery>;
export type GetChannelIdsFromListingsQueryResult = Apollo.QueryResult<Types.GetChannelIdsFromListings, Types.GetChannelIdsFromListingsVariables>;
export function refetchGetChannelIdsFromListings(variables: Types.GetChannelIdsFromListingsVariables) {
      return { query: GetChannelIdsFromListingsDocument, variables: variables }
    }
export const GetChannelSkusFromListingsDocument = gql`
    query getChannelSkusFromListings($filter: RequestFilter!) {
  listingsForTPID(filter: $filter) {
    results {
      channelSku
    }
    totalResults
  }
}
    `;

/**
 * __useGetChannelSkusFromListings__
 *
 * To run a query within a React component, call `useGetChannelSkusFromListings` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelSkusFromListings` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelSkusFromListings({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetChannelSkusFromListings(baseOptions: Apollo.QueryHookOptions<Types.GetChannelSkusFromListings, Types.GetChannelSkusFromListingsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetChannelSkusFromListings, Types.GetChannelSkusFromListingsVariables>(GetChannelSkusFromListingsDocument, options);
      }
export function useGetChannelSkusFromListingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChannelSkusFromListings, Types.GetChannelSkusFromListingsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetChannelSkusFromListings, Types.GetChannelSkusFromListingsVariables>(GetChannelSkusFromListingsDocument, options);
        }
export type GetChannelSkusFromListingsHookResult = ReturnType<typeof useGetChannelSkusFromListings>;
export type GetChannelSkusFromListingsLazyQueryHookResult = ReturnType<typeof useGetChannelSkusFromListingsLazyQuery>;
export type GetChannelSkusFromListingsQueryResult = Apollo.QueryResult<Types.GetChannelSkusFromListings, Types.GetChannelSkusFromListingsVariables>;
export function refetchGetChannelSkusFromListings(variables: Types.GetChannelSkusFromListingsVariables) {
      return { query: GetChannelSkusFromListingsDocument, variables: variables }
    }
export const GetListingsForTpidToolDocument = gql`
    query getListingsForTPIDTool($filter: RequestFilter!, $pagination: Pagination, $sort: [SortDescriptor!]) {
  listingsForTPID(filter: $filter, pagination: $pagination, sort: $sort) {
    results {
      acquiredBrand
      channelid
      channelMarket
      name
      thrasioProductId
      fulfillmentChannel
      fullStoreName
      channelSku
    }
    totalResults
  }
}
    `;

/**
 * __useGetListingsForTpidTool__
 *
 * To run a query within a React component, call `useGetListingsForTpidTool` and pass it any options that fit your needs.
 * When your component renders, `useGetListingsForTpidTool` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListingsForTpidTool({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetListingsForTpidTool(baseOptions: Apollo.QueryHookOptions<Types.GetListingsForTpidTool, Types.GetListingsForTpidToolVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetListingsForTpidTool, Types.GetListingsForTpidToolVariables>(GetListingsForTpidToolDocument, options);
      }
export function useGetListingsForTpidToolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetListingsForTpidTool, Types.GetListingsForTpidToolVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetListingsForTpidTool, Types.GetListingsForTpidToolVariables>(GetListingsForTpidToolDocument, options);
        }
export type GetListingsForTpidToolHookResult = ReturnType<typeof useGetListingsForTpidTool>;
export type GetListingsForTpidToolLazyQueryHookResult = ReturnType<typeof useGetListingsForTpidToolLazyQuery>;
export type GetListingsForTpidToolQueryResult = Apollo.QueryResult<Types.GetListingsForTpidTool, Types.GetListingsForTpidToolVariables>;
export function refetchGetListingsForTpidTool(variables: Types.GetListingsForTpidToolVariables) {
      return { query: GetListingsForTpidToolDocument, variables: variables }
    }
export const GetTpidsFromListingsDocument = gql`
    query getTPIDSFromListings($filter: RequestFilter!, $pagination: Pagination, $sort: [SortDescriptor!]) {
  listingsForTPID(filter: $filter, pagination: $pagination, sort: $sort) {
    results {
      thrasioProductId
    }
    totalResults
  }
}
    `;

/**
 * __useGetTpidsFromListings__
 *
 * To run a query within a React component, call `useGetTpidsFromListings` and pass it any options that fit your needs.
 * When your component renders, `useGetTpidsFromListings` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTpidsFromListings({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetTpidsFromListings(baseOptions: Apollo.QueryHookOptions<Types.GetTpidsFromListings, Types.GetTpidsFromListingsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTpidsFromListings, Types.GetTpidsFromListingsVariables>(GetTpidsFromListingsDocument, options);
      }
export function useGetTpidsFromListingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTpidsFromListings, Types.GetTpidsFromListingsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTpidsFromListings, Types.GetTpidsFromListingsVariables>(GetTpidsFromListingsDocument, options);
        }
export type GetTpidsFromListingsHookResult = ReturnType<typeof useGetTpidsFromListings>;
export type GetTpidsFromListingsLazyQueryHookResult = ReturnType<typeof useGetTpidsFromListingsLazyQuery>;
export type GetTpidsFromListingsQueryResult = Apollo.QueryResult<Types.GetTpidsFromListings, Types.GetTpidsFromListingsVariables>;
export function refetchGetTpidsFromListings(variables: Types.GetTpidsFromListingsVariables) {
      return { query: GetTpidsFromListingsDocument, variables: variables }
    }
export const ListScAutomationToolsMerchantsWithDateRangeQueryDocument = gql`
    query ListSCAutomationToolsMerchantsWithDateRangeQuery {
  listSCAutomationToolsMerchantsWithDateRange {
    results {
      from_date
      merchant
      to_date
    }
  }
}
    `;

/**
 * __useListScAutomationToolsMerchantsWithDateRangeQuery__
 *
 * To run a query within a React component, call `useListScAutomationToolsMerchantsWithDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useListScAutomationToolsMerchantsWithDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListScAutomationToolsMerchantsWithDateRangeQuery({
 *   variables: {
 *   },
 * });
 */
export function useListScAutomationToolsMerchantsWithDateRangeQuery(baseOptions?: Apollo.QueryHookOptions<Types.ListScAutomationToolsMerchantsWithDateRangeQuery, Types.ListScAutomationToolsMerchantsWithDateRangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListScAutomationToolsMerchantsWithDateRangeQuery, Types.ListScAutomationToolsMerchantsWithDateRangeQueryVariables>(ListScAutomationToolsMerchantsWithDateRangeQueryDocument, options);
      }
export function useListScAutomationToolsMerchantsWithDateRangeQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListScAutomationToolsMerchantsWithDateRangeQuery, Types.ListScAutomationToolsMerchantsWithDateRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListScAutomationToolsMerchantsWithDateRangeQuery, Types.ListScAutomationToolsMerchantsWithDateRangeQueryVariables>(ListScAutomationToolsMerchantsWithDateRangeQueryDocument, options);
        }
export type ListScAutomationToolsMerchantsWithDateRangeQueryHookResult = ReturnType<typeof useListScAutomationToolsMerchantsWithDateRangeQuery>;
export type ListScAutomationToolsMerchantsWithDateRangeQueryLazyQueryHookResult = ReturnType<typeof useListScAutomationToolsMerchantsWithDateRangeQueryLazyQuery>;
export type ListScAutomationToolsMerchantsWithDateRangeQueryQueryResult = Apollo.QueryResult<Types.ListScAutomationToolsMerchantsWithDateRangeQuery, Types.ListScAutomationToolsMerchantsWithDateRangeQueryVariables>;
export function refetchListScAutomationToolsMerchantsWithDateRangeQuery(variables?: Types.ListScAutomationToolsMerchantsWithDateRangeQueryVariables) {
      return { query: ListScAutomationToolsMerchantsWithDateRangeQueryDocument, variables: variables }
    }
export const ListScAutomationToolsReportsQueryDocument = gql`
    query ListSCAutomationToolsReportsQuery($filter: RequestFilter, $pagination: Pagination, $sort: [SortDescriptor]) {
  listSCAutomationToolsReports(
    filter: $filter
    pagination: $pagination
    sort: $sort
  ) {
    results {
      id
      merchant
      from_date
      to_date
      currency
      created_at
      status
    }
    totalResults
  }
}
    `;

/**
 * __useListScAutomationToolsReportsQuery__
 *
 * To run a query within a React component, call `useListScAutomationToolsReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListScAutomationToolsReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListScAutomationToolsReportsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useListScAutomationToolsReportsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ListScAutomationToolsReportsQuery, Types.ListScAutomationToolsReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListScAutomationToolsReportsQuery, Types.ListScAutomationToolsReportsQueryVariables>(ListScAutomationToolsReportsQueryDocument, options);
      }
export function useListScAutomationToolsReportsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListScAutomationToolsReportsQuery, Types.ListScAutomationToolsReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListScAutomationToolsReportsQuery, Types.ListScAutomationToolsReportsQueryVariables>(ListScAutomationToolsReportsQueryDocument, options);
        }
export type ListScAutomationToolsReportsQueryHookResult = ReturnType<typeof useListScAutomationToolsReportsQuery>;
export type ListScAutomationToolsReportsQueryLazyQueryHookResult = ReturnType<typeof useListScAutomationToolsReportsQueryLazyQuery>;
export type ListScAutomationToolsReportsQueryQueryResult = Apollo.QueryResult<Types.ListScAutomationToolsReportsQuery, Types.ListScAutomationToolsReportsQueryVariables>;
export function refetchListScAutomationToolsReportsQuery(variables?: Types.ListScAutomationToolsReportsQueryVariables) {
      return { query: ListScAutomationToolsReportsQueryDocument, variables: variables }
    }
export const RequestScAutomationToolReportFileUrlMutationDocument = gql`
    mutation RequestSCAutomationToolReportFileUrlMutation($report_id: Int!) {
  requestSCAutomationToolReportFileUrl(report_id: $report_id) {
    url
  }
}
    `;
export type RequestScAutomationToolReportFileUrlMutationMutationFn = Apollo.MutationFunction<Types.RequestScAutomationToolReportFileUrlMutation, Types.RequestScAutomationToolReportFileUrlMutationVariables>;

/**
 * __useRequestScAutomationToolReportFileUrlMutation__
 *
 * To run a mutation, you first call `useRequestScAutomationToolReportFileUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestScAutomationToolReportFileUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestScAutomationToolReportFileUrlMutation, { data, loading, error }] = useRequestScAutomationToolReportFileUrlMutation({
 *   variables: {
 *      report_id: // value for 'report_id'
 *   },
 * });
 */
export function useRequestScAutomationToolReportFileUrlMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestScAutomationToolReportFileUrlMutation, Types.RequestScAutomationToolReportFileUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestScAutomationToolReportFileUrlMutation, Types.RequestScAutomationToolReportFileUrlMutationVariables>(RequestScAutomationToolReportFileUrlMutationDocument, options);
      }
export type RequestScAutomationToolReportFileUrlMutationHookResult = ReturnType<typeof useRequestScAutomationToolReportFileUrlMutation>;
export type RequestScAutomationToolReportFileUrlMutationMutationResult = Apollo.MutationResult<Types.RequestScAutomationToolReportFileUrlMutation>;
export type RequestScAutomationToolReportFileUrlMutationMutationOptions = Apollo.BaseMutationOptions<Types.RequestScAutomationToolReportFileUrlMutation, Types.RequestScAutomationToolReportFileUrlMutationVariables>;
export const ListScAutomationToolsMerchantsQueryDocument = gql`
    query ListSCAutomationToolsMerchantsQuery {
  listSCAutomationToolsMerchants {
    results
  }
}
    `;

/**
 * __useListScAutomationToolsMerchantsQuery__
 *
 * To run a query within a React component, call `useListScAutomationToolsMerchantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListScAutomationToolsMerchantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListScAutomationToolsMerchantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListScAutomationToolsMerchantsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ListScAutomationToolsMerchantsQuery, Types.ListScAutomationToolsMerchantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListScAutomationToolsMerchantsQuery, Types.ListScAutomationToolsMerchantsQueryVariables>(ListScAutomationToolsMerchantsQueryDocument, options);
      }
export function useListScAutomationToolsMerchantsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListScAutomationToolsMerchantsQuery, Types.ListScAutomationToolsMerchantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListScAutomationToolsMerchantsQuery, Types.ListScAutomationToolsMerchantsQueryVariables>(ListScAutomationToolsMerchantsQueryDocument, options);
        }
export type ListScAutomationToolsMerchantsQueryHookResult = ReturnType<typeof useListScAutomationToolsMerchantsQuery>;
export type ListScAutomationToolsMerchantsQueryLazyQueryHookResult = ReturnType<typeof useListScAutomationToolsMerchantsQueryLazyQuery>;
export type ListScAutomationToolsMerchantsQueryQueryResult = Apollo.QueryResult<Types.ListScAutomationToolsMerchantsQuery, Types.ListScAutomationToolsMerchantsQueryVariables>;
export function refetchListScAutomationToolsMerchantsQuery(variables?: Types.ListScAutomationToolsMerchantsQueryVariables) {
      return { query: ListScAutomationToolsMerchantsQueryDocument, variables: variables }
    }
export const RequestScAutomationToolsReportStatusMutationDocument = gql`
    mutation RequestSCAutomationToolsReportStatusMutation($input: requestSCAutomationToolsReportStatusInput) {
  requestSCAutomationToolsReportStatus(input: $input) {
    status
  }
}
    `;
export type RequestScAutomationToolsReportStatusMutationMutationFn = Apollo.MutationFunction<Types.RequestScAutomationToolsReportStatusMutation, Types.RequestScAutomationToolsReportStatusMutationVariables>;

/**
 * __useRequestScAutomationToolsReportStatusMutation__
 *
 * To run a mutation, you first call `useRequestScAutomationToolsReportStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestScAutomationToolsReportStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestScAutomationToolsReportStatusMutation, { data, loading, error }] = useRequestScAutomationToolsReportStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestScAutomationToolsReportStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestScAutomationToolsReportStatusMutation, Types.RequestScAutomationToolsReportStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestScAutomationToolsReportStatusMutation, Types.RequestScAutomationToolsReportStatusMutationVariables>(RequestScAutomationToolsReportStatusMutationDocument, options);
      }
export type RequestScAutomationToolsReportStatusMutationHookResult = ReturnType<typeof useRequestScAutomationToolsReportStatusMutation>;
export type RequestScAutomationToolsReportStatusMutationMutationResult = Apollo.MutationResult<Types.RequestScAutomationToolsReportStatusMutation>;
export type RequestScAutomationToolsReportStatusMutationMutationOptions = Apollo.BaseMutationOptions<Types.RequestScAutomationToolsReportStatusMutation, Types.RequestScAutomationToolsReportStatusMutationVariables>;
export const RequestScAutomationToolsTriggerGenerateReportMutationDocument = gql`
    mutation RequestSCAutomationToolsTriggerGenerateReportMutation($input: requestSCAutomationToolsTriggerGenerateReportInput) {
  requestSCAutomationToolsTriggerGenerateReport(input: $input) {
    report_id
  }
}
    `;
export type RequestScAutomationToolsTriggerGenerateReportMutationMutationFn = Apollo.MutationFunction<Types.RequestScAutomationToolsTriggerGenerateReportMutation, Types.RequestScAutomationToolsTriggerGenerateReportMutationVariables>;

/**
 * __useRequestScAutomationToolsTriggerGenerateReportMutation__
 *
 * To run a mutation, you first call `useRequestScAutomationToolsTriggerGenerateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestScAutomationToolsTriggerGenerateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestScAutomationToolsTriggerGenerateReportMutation, { data, loading, error }] = useRequestScAutomationToolsTriggerGenerateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestScAutomationToolsTriggerGenerateReportMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestScAutomationToolsTriggerGenerateReportMutation, Types.RequestScAutomationToolsTriggerGenerateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestScAutomationToolsTriggerGenerateReportMutation, Types.RequestScAutomationToolsTriggerGenerateReportMutationVariables>(RequestScAutomationToolsTriggerGenerateReportMutationDocument, options);
      }
export type RequestScAutomationToolsTriggerGenerateReportMutationHookResult = ReturnType<typeof useRequestScAutomationToolsTriggerGenerateReportMutation>;
export type RequestScAutomationToolsTriggerGenerateReportMutationMutationResult = Apollo.MutationResult<Types.RequestScAutomationToolsTriggerGenerateReportMutation>;
export type RequestScAutomationToolsTriggerGenerateReportMutationMutationOptions = Apollo.BaseMutationOptions<Types.RequestScAutomationToolsTriggerGenerateReportMutation, Types.RequestScAutomationToolsTriggerGenerateReportMutationVariables>;
export const GetScAutomationToolsValidationStatusQueryDocument = gql`
    query GetSCAutomationToolsValidationStatusQuery($task: String) {
  getSCAutomationToolsValidationStatus(task: $task) {
    result
    status
  }
}
    `;

/**
 * __useGetScAutomationToolsValidationStatusQuery__
 *
 * To run a query within a React component, call `useGetScAutomationToolsValidationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScAutomationToolsValidationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScAutomationToolsValidationStatusQuery({
 *   variables: {
 *      task: // value for 'task'
 *   },
 * });
 */
export function useGetScAutomationToolsValidationStatusQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetScAutomationToolsValidationStatusQuery, Types.GetScAutomationToolsValidationStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScAutomationToolsValidationStatusQuery, Types.GetScAutomationToolsValidationStatusQueryVariables>(GetScAutomationToolsValidationStatusQueryDocument, options);
      }
export function useGetScAutomationToolsValidationStatusQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScAutomationToolsValidationStatusQuery, Types.GetScAutomationToolsValidationStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScAutomationToolsValidationStatusQuery, Types.GetScAutomationToolsValidationStatusQueryVariables>(GetScAutomationToolsValidationStatusQueryDocument, options);
        }
export type GetScAutomationToolsValidationStatusQueryHookResult = ReturnType<typeof useGetScAutomationToolsValidationStatusQuery>;
export type GetScAutomationToolsValidationStatusQueryLazyQueryHookResult = ReturnType<typeof useGetScAutomationToolsValidationStatusQueryLazyQuery>;
export type GetScAutomationToolsValidationStatusQueryQueryResult = Apollo.QueryResult<Types.GetScAutomationToolsValidationStatusQuery, Types.GetScAutomationToolsValidationStatusQueryVariables>;
export function refetchGetScAutomationToolsValidationStatusQuery(variables?: Types.GetScAutomationToolsValidationStatusQueryVariables) {
      return { query: GetScAutomationToolsValidationStatusQueryDocument, variables: variables }
    }
export const ListScAutomationToolsCountryMarketplaceMapQueryDocument = gql`
    query ListSCAutomationToolsCountryMarketplaceMapQuery {
  listSCAutomationToolsCountryMarketplaceMap {
    results {
      country
      marketplace
    }
  }
}
    `;

/**
 * __useListScAutomationToolsCountryMarketplaceMapQuery__
 *
 * To run a query within a React component, call `useListScAutomationToolsCountryMarketplaceMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useListScAutomationToolsCountryMarketplaceMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListScAutomationToolsCountryMarketplaceMapQuery({
 *   variables: {
 *   },
 * });
 */
export function useListScAutomationToolsCountryMarketplaceMapQuery(baseOptions?: Apollo.QueryHookOptions<Types.ListScAutomationToolsCountryMarketplaceMapQuery, Types.ListScAutomationToolsCountryMarketplaceMapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListScAutomationToolsCountryMarketplaceMapQuery, Types.ListScAutomationToolsCountryMarketplaceMapQueryVariables>(ListScAutomationToolsCountryMarketplaceMapQueryDocument, options);
      }
export function useListScAutomationToolsCountryMarketplaceMapQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListScAutomationToolsCountryMarketplaceMapQuery, Types.ListScAutomationToolsCountryMarketplaceMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListScAutomationToolsCountryMarketplaceMapQuery, Types.ListScAutomationToolsCountryMarketplaceMapQueryVariables>(ListScAutomationToolsCountryMarketplaceMapQueryDocument, options);
        }
export type ListScAutomationToolsCountryMarketplaceMapQueryHookResult = ReturnType<typeof useListScAutomationToolsCountryMarketplaceMapQuery>;
export type ListScAutomationToolsCountryMarketplaceMapQueryLazyQueryHookResult = ReturnType<typeof useListScAutomationToolsCountryMarketplaceMapQueryLazyQuery>;
export type ListScAutomationToolsCountryMarketplaceMapQueryQueryResult = Apollo.QueryResult<Types.ListScAutomationToolsCountryMarketplaceMapQuery, Types.ListScAutomationToolsCountryMarketplaceMapQueryVariables>;
export function refetchListScAutomationToolsCountryMarketplaceMapQuery(variables?: Types.ListScAutomationToolsCountryMarketplaceMapQueryVariables) {
      return { query: ListScAutomationToolsCountryMarketplaceMapQueryDocument, variables: variables }
    }
export const RequestScAutomationToolsTriggerGenerateReportManualMutationDocument = gql`
    mutation RequestSCAutomationToolsTriggerGenerateReportManualMutation($input: requestSCAutomationToolsTriggerGenerateReportManualInput!) {
  requestSCAutomationToolsTriggerGenerateReportManual(input: $input) {
    status
  }
}
    `;
export type RequestScAutomationToolsTriggerGenerateReportManualMutationMutationFn = Apollo.MutationFunction<Types.RequestScAutomationToolsTriggerGenerateReportManualMutation, Types.RequestScAutomationToolsTriggerGenerateReportManualMutationVariables>;

/**
 * __useRequestScAutomationToolsTriggerGenerateReportManualMutation__
 *
 * To run a mutation, you first call `useRequestScAutomationToolsTriggerGenerateReportManualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestScAutomationToolsTriggerGenerateReportManualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestScAutomationToolsTriggerGenerateReportManualMutation, { data, loading, error }] = useRequestScAutomationToolsTriggerGenerateReportManualMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestScAutomationToolsTriggerGenerateReportManualMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestScAutomationToolsTriggerGenerateReportManualMutation, Types.RequestScAutomationToolsTriggerGenerateReportManualMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestScAutomationToolsTriggerGenerateReportManualMutation, Types.RequestScAutomationToolsTriggerGenerateReportManualMutationVariables>(RequestScAutomationToolsTriggerGenerateReportManualMutationDocument, options);
      }
export type RequestScAutomationToolsTriggerGenerateReportManualMutationHookResult = ReturnType<typeof useRequestScAutomationToolsTriggerGenerateReportManualMutation>;
export type RequestScAutomationToolsTriggerGenerateReportManualMutationMutationResult = Apollo.MutationResult<Types.RequestScAutomationToolsTriggerGenerateReportManualMutation>;
export type RequestScAutomationToolsTriggerGenerateReportManualMutationMutationOptions = Apollo.BaseMutationOptions<Types.RequestScAutomationToolsTriggerGenerateReportManualMutation, Types.RequestScAutomationToolsTriggerGenerateReportManualMutationVariables>;
export const RequestScAutomationToolsUploadUrlForManualDataUploadDocument = gql`
    mutation requestSCAutomationToolsUploadUrlForManualDataUpload($merchant: String, $market: String) {
  requestSCAutomationToolsUploadUrlForManualDataUpload(
    merchant: $merchant
    market: $market
  ) {
    ...signedUploadFragment
  }
}
    ${SignedUploadFragment}`;
export type RequestScAutomationToolsUploadUrlForManualDataUploadMutationFn = Apollo.MutationFunction<Types.RequestScAutomationToolsUploadUrlForManualDataUpload, Types.RequestScAutomationToolsUploadUrlForManualDataUploadVariables>;

/**
 * __useRequestScAutomationToolsUploadUrlForManualDataUpload__
 *
 * To run a mutation, you first call `useRequestScAutomationToolsUploadUrlForManualDataUpload` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestScAutomationToolsUploadUrlForManualDataUpload` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestScAutomationToolsUploadUrlForManualDataUpload, { data, loading, error }] = useRequestScAutomationToolsUploadUrlForManualDataUpload({
 *   variables: {
 *      merchant: // value for 'merchant'
 *      market: // value for 'market'
 *   },
 * });
 */
export function useRequestScAutomationToolsUploadUrlForManualDataUpload(baseOptions?: Apollo.MutationHookOptions<Types.RequestScAutomationToolsUploadUrlForManualDataUpload, Types.RequestScAutomationToolsUploadUrlForManualDataUploadVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestScAutomationToolsUploadUrlForManualDataUpload, Types.RequestScAutomationToolsUploadUrlForManualDataUploadVariables>(RequestScAutomationToolsUploadUrlForManualDataUploadDocument, options);
      }
export type RequestScAutomationToolsUploadUrlForManualDataUploadHookResult = ReturnType<typeof useRequestScAutomationToolsUploadUrlForManualDataUpload>;
export type RequestScAutomationToolsUploadUrlForManualDataUploadMutationResult = Apollo.MutationResult<Types.RequestScAutomationToolsUploadUrlForManualDataUpload>;
export type RequestScAutomationToolsUploadUrlForManualDataUploadMutationOptions = Apollo.BaseMutationOptions<Types.RequestScAutomationToolsUploadUrlForManualDataUpload, Types.RequestScAutomationToolsUploadUrlForManualDataUploadVariables>;
export const RequestScAutomationToolsValidationForBatchManualDataUploadMutationDocument = gql`
    mutation RequestSCAutomationToolsValidationForBatchManualDataUploadMutation($merchants: [ValidationForBatchUpload]) {
  requestSCAutomationToolsValidationForBatchManualDataUpload(
    merchants: $merchants
  ) {
    status
  }
}
    `;
export type RequestScAutomationToolsValidationForBatchManualDataUploadMutationMutationFn = Apollo.MutationFunction<Types.RequestScAutomationToolsValidationForBatchManualDataUploadMutation, Types.RequestScAutomationToolsValidationForBatchManualDataUploadMutationVariables>;

/**
 * __useRequestScAutomationToolsValidationForBatchManualDataUploadMutation__
 *
 * To run a mutation, you first call `useRequestScAutomationToolsValidationForBatchManualDataUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestScAutomationToolsValidationForBatchManualDataUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestScAutomationToolsValidationForBatchManualDataUploadMutation, { data, loading, error }] = useRequestScAutomationToolsValidationForBatchManualDataUploadMutation({
 *   variables: {
 *      merchants: // value for 'merchants'
 *   },
 * });
 */
export function useRequestScAutomationToolsValidationForBatchManualDataUploadMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestScAutomationToolsValidationForBatchManualDataUploadMutation, Types.RequestScAutomationToolsValidationForBatchManualDataUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestScAutomationToolsValidationForBatchManualDataUploadMutation, Types.RequestScAutomationToolsValidationForBatchManualDataUploadMutationVariables>(RequestScAutomationToolsValidationForBatchManualDataUploadMutationDocument, options);
      }
export type RequestScAutomationToolsValidationForBatchManualDataUploadMutationHookResult = ReturnType<typeof useRequestScAutomationToolsValidationForBatchManualDataUploadMutation>;
export type RequestScAutomationToolsValidationForBatchManualDataUploadMutationMutationResult = Apollo.MutationResult<Types.RequestScAutomationToolsValidationForBatchManualDataUploadMutation>;
export type RequestScAutomationToolsValidationForBatchManualDataUploadMutationMutationOptions = Apollo.BaseMutationOptions<Types.RequestScAutomationToolsValidationForBatchManualDataUploadMutation, Types.RequestScAutomationToolsValidationForBatchManualDataUploadMutationVariables>;
export const DiligenceBrandsQueryDocument = gql`
    query DiligenceBrandsQuery {
  reviewAuditDiligenceBrands(filter: "") {
    name
  }
}
    `;

/**
 * __useDiligenceBrandsQuery__
 *
 * To run a query within a React component, call `useDiligenceBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiligenceBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiligenceBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDiligenceBrandsQuery(baseOptions?: Apollo.QueryHookOptions<Types.DiligenceBrandsQuery, Types.DiligenceBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DiligenceBrandsQuery, Types.DiligenceBrandsQueryVariables>(DiligenceBrandsQueryDocument, options);
      }
export function useDiligenceBrandsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DiligenceBrandsQuery, Types.DiligenceBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DiligenceBrandsQuery, Types.DiligenceBrandsQueryVariables>(DiligenceBrandsQueryDocument, options);
        }
export type DiligenceBrandsQueryHookResult = ReturnType<typeof useDiligenceBrandsQuery>;
export type DiligenceBrandsQueryLazyQueryHookResult = ReturnType<typeof useDiligenceBrandsQueryLazyQuery>;
export type DiligenceBrandsQueryQueryResult = Apollo.QueryResult<Types.DiligenceBrandsQuery, Types.DiligenceBrandsQueryVariables>;
export function refetchDiligenceBrandsQuery(variables?: Types.DiligenceBrandsQueryVariables) {
      return { query: DiligenceBrandsQueryDocument, variables: variables }
    }
export const ReviewAuditQueryDocument = gql`
    query reviewAuditQuery($targetBrand: ID!, $targetAsins: [ID!]! = [], $isTargetThrasioBrand: Boolean = false) {
  getReviewToTotalRating(
    targetBrand: $targetBrand
    targetAsins: $targetAsins
    isTargetThrasioBrand: $isTargetThrasioBrand
  ) {
    ...RAMetricHistograms
    reviewAuditSummary {
      ...RASummaryFields
    }
    errors {
      __typename
      ...RAErrorInvalidTargetAsinsFields
      ...RAErrorNotEnoughReferenceDataFields
      ...RAErrorNotEnoughTargetDataFields
    }
  }
  getHelpfulReviewRates(
    targetBrand: $targetBrand
    targetAsins: $targetAsins
    isTargetThrasioBrand: $isTargetThrasioBrand
  ) {
    ...RAMetricHistograms
    reviewAuditSummary {
      ...RASummaryFields
    }
    errors {
      __typename
      ...RAErrorInvalidTargetAsinsFields
      ...RAErrorNotEnoughReferenceDataFields
      ...RAErrorNotEnoughTargetDataFields
    }
  }
  getReviewRates(
    targetBrand: $targetBrand
    targetAsins: $targetAsins
    isTargetThrasioBrand: $isTargetThrasioBrand
  ) {
    ...RAMetricHistograms
    reviewAuditSummary {
      ...RASummaryFields
    }
    errors {
      __typename
      ...RAErrorInvalidTargetAsinsFields
      ...RAErrorNotEnoughReferenceDataFields
      ...RAErrorNotEnoughTargetDataFields
    }
  }
}
    ${RaMetricHistograms}
${RaSummaryFields}
${RaErrorInvalidTargetAsinsFields}
${RaErrorNotEnoughReferenceDataFields}
${RaErrorNotEnoughTargetDataFields}`;

/**
 * __useReviewAuditQuery__
 *
 * To run a query within a React component, call `useReviewAuditQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewAuditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewAuditQuery({
 *   variables: {
 *      targetBrand: // value for 'targetBrand'
 *      targetAsins: // value for 'targetAsins'
 *      isTargetThrasioBrand: // value for 'isTargetThrasioBrand'
 *   },
 * });
 */
export function useReviewAuditQuery(baseOptions: Apollo.QueryHookOptions<Types.ReviewAuditQuery, Types.ReviewAuditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReviewAuditQuery, Types.ReviewAuditQueryVariables>(ReviewAuditQueryDocument, options);
      }
export function useReviewAuditQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReviewAuditQuery, Types.ReviewAuditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReviewAuditQuery, Types.ReviewAuditQueryVariables>(ReviewAuditQueryDocument, options);
        }
export type ReviewAuditQueryHookResult = ReturnType<typeof useReviewAuditQuery>;
export type ReviewAuditQueryLazyQueryHookResult = ReturnType<typeof useReviewAuditQueryLazyQuery>;
export type ReviewAuditQueryQueryResult = Apollo.QueryResult<Types.ReviewAuditQuery, Types.ReviewAuditQueryVariables>;
export function refetchReviewAuditQuery(variables: Types.ReviewAuditQueryVariables) {
      return { query: ReviewAuditQueryDocument, variables: variables }
    }
export const ProductIntegrityGetReviewDocument = gql`
    query productIntegrityGetReview($id: String!) {
  productIntegrityGetReview(id: $id) {
    result {
      id
      createdOn
      updatedOn
      reviewerEmail
      reviewerName
      certificateNeeded
      childAsins
      notes
      sampleQuantity
      sampleType
      status
      testTurnaroundTime
      testingLabs {
        contactName
        email
        faxNumber
        location
        mobileNumber
        name
        phoneNumber
      }
      otherLabs {
        labId
        labName
        labContact
        labAddress
        labEmail
        labPhoneNumber
        labMobileNumber
        labFax
      }
    }
    errors
    success
  }
}
    `;

/**
 * __useProductIntegrityGetReview__
 *
 * To run a query within a React component, call `useProductIntegrityGetReview` and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityGetReview` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductIntegrityGetReview({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductIntegrityGetReview(baseOptions: Apollo.QueryHookOptions<Types.ProductIntegrityGetReview, Types.ProductIntegrityGetReviewVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductIntegrityGetReview, Types.ProductIntegrityGetReviewVariables>(ProductIntegrityGetReviewDocument, options);
      }
export function useProductIntegrityGetReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductIntegrityGetReview, Types.ProductIntegrityGetReviewVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductIntegrityGetReview, Types.ProductIntegrityGetReviewVariables>(ProductIntegrityGetReviewDocument, options);
        }
export type ProductIntegrityGetReviewHookResult = ReturnType<typeof useProductIntegrityGetReview>;
export type ProductIntegrityGetReviewLazyQueryHookResult = ReturnType<typeof useProductIntegrityGetReviewLazyQuery>;
export type ProductIntegrityGetReviewQueryResult = Apollo.QueryResult<Types.ProductIntegrityGetReview, Types.ProductIntegrityGetReviewVariables>;
export function refetchProductIntegrityGetReview(variables: Types.ProductIntegrityGetReviewVariables) {
      return { query: ProductIntegrityGetReviewDocument, variables: variables }
    }
export const ProductIntegrityGetStepFourObjDocument = gql`
    query productIntegrityGetStepFourObj($requestId: String!) {
  productIntegrityGetStepFourObj(requestId: $requestId) {
    errors
    success
    result {
      id
      status
    }
  }
}
    `;

/**
 * __useProductIntegrityGetStepFourObj__
 *
 * To run a query within a React component, call `useProductIntegrityGetStepFourObj` and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityGetStepFourObj` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductIntegrityGetStepFourObj({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useProductIntegrityGetStepFourObj(baseOptions: Apollo.QueryHookOptions<Types.ProductIntegrityGetStepFourObj, Types.ProductIntegrityGetStepFourObjVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductIntegrityGetStepFourObj, Types.ProductIntegrityGetStepFourObjVariables>(ProductIntegrityGetStepFourObjDocument, options);
      }
export function useProductIntegrityGetStepFourObjLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductIntegrityGetStepFourObj, Types.ProductIntegrityGetStepFourObjVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductIntegrityGetStepFourObj, Types.ProductIntegrityGetStepFourObjVariables>(ProductIntegrityGetStepFourObjDocument, options);
        }
export type ProductIntegrityGetStepFourObjHookResult = ReturnType<typeof useProductIntegrityGetStepFourObj>;
export type ProductIntegrityGetStepFourObjLazyQueryHookResult = ReturnType<typeof useProductIntegrityGetStepFourObjLazyQuery>;
export type ProductIntegrityGetStepFourObjQueryResult = Apollo.QueryResult<Types.ProductIntegrityGetStepFourObj, Types.ProductIntegrityGetStepFourObjVariables>;
export function refetchProductIntegrityGetStepFourObj(variables: Types.ProductIntegrityGetStepFourObjVariables) {
      return { query: ProductIntegrityGetStepFourObjDocument, variables: variables }
    }
export const ProductIntegrityGetStepThreeObjsDocument = gql`
    query productIntegrityGetStepThreeObjs($id: String!) {
  productIntegrityGetStepThreeObjs(id: $id) {
    result {
      id
      links {
        approval
        url
        id
      }
      comment
      status
    }
    errors
    success
  }
}
    `;

/**
 * __useProductIntegrityGetStepThreeObjs__
 *
 * To run a query within a React component, call `useProductIntegrityGetStepThreeObjs` and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityGetStepThreeObjs` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductIntegrityGetStepThreeObjs({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductIntegrityGetStepThreeObjs(baseOptions: Apollo.QueryHookOptions<Types.ProductIntegrityGetStepThreeObjs, Types.ProductIntegrityGetStepThreeObjsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductIntegrityGetStepThreeObjs, Types.ProductIntegrityGetStepThreeObjsVariables>(ProductIntegrityGetStepThreeObjsDocument, options);
      }
export function useProductIntegrityGetStepThreeObjsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductIntegrityGetStepThreeObjs, Types.ProductIntegrityGetStepThreeObjsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductIntegrityGetStepThreeObjs, Types.ProductIntegrityGetStepThreeObjsVariables>(ProductIntegrityGetStepThreeObjsDocument, options);
        }
export type ProductIntegrityGetStepThreeObjsHookResult = ReturnType<typeof useProductIntegrityGetStepThreeObjs>;
export type ProductIntegrityGetStepThreeObjsLazyQueryHookResult = ReturnType<typeof useProductIntegrityGetStepThreeObjsLazyQuery>;
export type ProductIntegrityGetStepThreeObjsQueryResult = Apollo.QueryResult<Types.ProductIntegrityGetStepThreeObjs, Types.ProductIntegrityGetStepThreeObjsVariables>;
export function refetchProductIntegrityGetStepThreeObjs(variables: Types.ProductIntegrityGetStepThreeObjsVariables) {
      return { query: ProductIntegrityGetStepThreeObjsDocument, variables: variables }
    }
export const ProductIntegrityGetStepTwoObjsDocument = gql`
    query productIntegrityGetStepTwoObjs($id: String!) {
  productIntegrityGetStepTwoObjs(id: $id) {
    results {
      links {
        approval
        url
        id
      }
      status
      action
      comment
      id
      testingStartDate
    }
    errors
    success
  }
}
    `;

/**
 * __useProductIntegrityGetStepTwoObjs__
 *
 * To run a query within a React component, call `useProductIntegrityGetStepTwoObjs` and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityGetStepTwoObjs` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductIntegrityGetStepTwoObjs({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductIntegrityGetStepTwoObjs(baseOptions: Apollo.QueryHookOptions<Types.ProductIntegrityGetStepTwoObjs, Types.ProductIntegrityGetStepTwoObjsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductIntegrityGetStepTwoObjs, Types.ProductIntegrityGetStepTwoObjsVariables>(ProductIntegrityGetStepTwoObjsDocument, options);
      }
export function useProductIntegrityGetStepTwoObjsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductIntegrityGetStepTwoObjs, Types.ProductIntegrityGetStepTwoObjsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductIntegrityGetStepTwoObjs, Types.ProductIntegrityGetStepTwoObjsVariables>(ProductIntegrityGetStepTwoObjsDocument, options);
        }
export type ProductIntegrityGetStepTwoObjsHookResult = ReturnType<typeof useProductIntegrityGetStepTwoObjs>;
export type ProductIntegrityGetStepTwoObjsLazyQueryHookResult = ReturnType<typeof useProductIntegrityGetStepTwoObjsLazyQuery>;
export type ProductIntegrityGetStepTwoObjsQueryResult = Apollo.QueryResult<Types.ProductIntegrityGetStepTwoObjs, Types.ProductIntegrityGetStepTwoObjsVariables>;
export function refetchProductIntegrityGetStepTwoObjs(variables: Types.ProductIntegrityGetStepTwoObjsVariables) {
      return { query: ProductIntegrityGetStepTwoObjsDocument, variables: variables }
    }
export const ProductIntegrityRequestDetailsDocument = gql`
    query productIntegrityRequestDetails($id: String!) {
  productIntegrityRequestDetails(id: $id) {
    results {
      label
      valueV2
    }
    success
    errors
  }
}
    `;

/**
 * __useProductIntegrityRequestDetails__
 *
 * To run a query within a React component, call `useProductIntegrityRequestDetails` and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityRequestDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductIntegrityRequestDetails({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductIntegrityRequestDetails(baseOptions: Apollo.QueryHookOptions<Types.ProductIntegrityRequestDetails, Types.ProductIntegrityRequestDetailsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductIntegrityRequestDetails, Types.ProductIntegrityRequestDetailsVariables>(ProductIntegrityRequestDetailsDocument, options);
      }
export function useProductIntegrityRequestDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductIntegrityRequestDetails, Types.ProductIntegrityRequestDetailsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductIntegrityRequestDetails, Types.ProductIntegrityRequestDetailsVariables>(ProductIntegrityRequestDetailsDocument, options);
        }
export type ProductIntegrityRequestDetailsHookResult = ReturnType<typeof useProductIntegrityRequestDetails>;
export type ProductIntegrityRequestDetailsLazyQueryHookResult = ReturnType<typeof useProductIntegrityRequestDetailsLazyQuery>;
export type ProductIntegrityRequestDetailsQueryResult = Apollo.QueryResult<Types.ProductIntegrityRequestDetails, Types.ProductIntegrityRequestDetailsVariables>;
export function refetchProductIntegrityRequestDetails(variables: Types.ProductIntegrityRequestDetailsVariables) {
      return { query: ProductIntegrityRequestDetailsDocument, variables: variables }
    }
export const ProductIntegrityRequestMoreDetailsDocument = gql`
    query productIntegrityRequestMoreDetails($id: String!) {
  productIntegrityRequestMoreDetails(id: $id) {
    results {
      label
      valueV2
    }
    success
    errors
  }
}
    `;

/**
 * __useProductIntegrityRequestMoreDetails__
 *
 * To run a query within a React component, call `useProductIntegrityRequestMoreDetails` and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityRequestMoreDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductIntegrityRequestMoreDetails({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductIntegrityRequestMoreDetails(baseOptions: Apollo.QueryHookOptions<Types.ProductIntegrityRequestMoreDetails, Types.ProductIntegrityRequestMoreDetailsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductIntegrityRequestMoreDetails, Types.ProductIntegrityRequestMoreDetailsVariables>(ProductIntegrityRequestMoreDetailsDocument, options);
      }
export function useProductIntegrityRequestMoreDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductIntegrityRequestMoreDetails, Types.ProductIntegrityRequestMoreDetailsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductIntegrityRequestMoreDetails, Types.ProductIntegrityRequestMoreDetailsVariables>(ProductIntegrityRequestMoreDetailsDocument, options);
        }
export type ProductIntegrityRequestMoreDetailsHookResult = ReturnType<typeof useProductIntegrityRequestMoreDetails>;
export type ProductIntegrityRequestMoreDetailsLazyQueryHookResult = ReturnType<typeof useProductIntegrityRequestMoreDetailsLazyQuery>;
export type ProductIntegrityRequestMoreDetailsQueryResult = Apollo.QueryResult<Types.ProductIntegrityRequestMoreDetails, Types.ProductIntegrityRequestMoreDetailsVariables>;
export function refetchProductIntegrityRequestMoreDetails(variables: Types.ProductIntegrityRequestMoreDetailsVariables) {
      return { query: ProductIntegrityRequestMoreDetailsDocument, variables: variables }
    }
export const ProductIntegrityVendorInformationDocument = gql`
    query productIntegrityVendorInformation($requestId: String!) {
  productIntegrityVendorInformation(requestId: $requestId) {
    results {
      label
      valueV2
    }
    success
    errors
  }
}
    `;

/**
 * __useProductIntegrityVendorInformation__
 *
 * To run a query within a React component, call `useProductIntegrityVendorInformation` and pass it any options that fit your needs.
 * When your component renders, `useProductIntegrityVendorInformation` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductIntegrityVendorInformation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useProductIntegrityVendorInformation(baseOptions: Apollo.QueryHookOptions<Types.ProductIntegrityVendorInformation, Types.ProductIntegrityVendorInformationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductIntegrityVendorInformation, Types.ProductIntegrityVendorInformationVariables>(ProductIntegrityVendorInformationDocument, options);
      }
export function useProductIntegrityVendorInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductIntegrityVendorInformation, Types.ProductIntegrityVendorInformationVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductIntegrityVendorInformation, Types.ProductIntegrityVendorInformationVariables>(ProductIntegrityVendorInformationDocument, options);
        }
export type ProductIntegrityVendorInformationHookResult = ReturnType<typeof useProductIntegrityVendorInformation>;
export type ProductIntegrityVendorInformationLazyQueryHookResult = ReturnType<typeof useProductIntegrityVendorInformationLazyQuery>;
export type ProductIntegrityVendorInformationQueryResult = Apollo.QueryResult<Types.ProductIntegrityVendorInformation, Types.ProductIntegrityVendorInformationVariables>;
export function refetchProductIntegrityVendorInformation(variables: Types.ProductIntegrityVendorInformationVariables) {
      return { query: ProductIntegrityVendorInformationDocument, variables: variables }
    }
export const MyNotificationConfigDocument = gql`
    query MyNotificationConfig {
  myNotificationConfig {
    recipientId
    hmacAuthSignature
  }
}
    `;

/**
 * __useMyNotificationConfig__
 *
 * To run a query within a React component, call `useMyNotificationConfig` and pass it any options that fit your needs.
 * When your component renders, `useMyNotificationConfig` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyNotificationConfig({
 *   variables: {
 *   },
 * });
 */
export function useMyNotificationConfig(baseOptions?: Apollo.QueryHookOptions<Types.MyNotificationConfig, Types.MyNotificationConfigVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MyNotificationConfig, Types.MyNotificationConfigVariables>(MyNotificationConfigDocument, options);
      }
export function useMyNotificationConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MyNotificationConfig, Types.MyNotificationConfigVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MyNotificationConfig, Types.MyNotificationConfigVariables>(MyNotificationConfigDocument, options);
        }
export type MyNotificationConfigHookResult = ReturnType<typeof useMyNotificationConfig>;
export type MyNotificationConfigLazyQueryHookResult = ReturnType<typeof useMyNotificationConfigLazyQuery>;
export type MyNotificationConfigQueryResult = Apollo.QueryResult<Types.MyNotificationConfig, Types.MyNotificationConfigVariables>;
export function refetchMyNotificationConfig(variables?: Types.MyNotificationConfigVariables) {
      return { query: MyNotificationConfigDocument, variables: variables }
    }
export const UserInfoDocument = gql`
    query UserInfo {
  me {
    title
    avatarImageURLs {
      url
    }
  }
}
    `;

/**
 * __useUserInfo__
 *
 * To run a query within a React component, call `useUserInfo` and pass it any options that fit your needs.
 * When your component renders, `useUserInfo` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfo({
 *   variables: {
 *   },
 * });
 */
export function useUserInfo(baseOptions?: Apollo.QueryHookOptions<Types.UserInfo, Types.UserInfoVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserInfo, Types.UserInfoVariables>(UserInfoDocument, options);
      }
export function useUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserInfo, Types.UserInfoVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserInfo, Types.UserInfoVariables>(UserInfoDocument, options);
        }
export type UserInfoHookResult = ReturnType<typeof useUserInfo>;
export type UserInfoLazyQueryHookResult = ReturnType<typeof useUserInfoLazyQuery>;
export type UserInfoQueryResult = Apollo.QueryResult<Types.UserInfo, Types.UserInfoVariables>;
export function refetchUserInfo(variables?: Types.UserInfoVariables) {
      return { query: UserInfoDocument, variables: variables }
    }